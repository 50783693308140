export async function loadDataObjectFromStaticCSV<T extends string[][] = string[][]>(filename: string) {
  const cacheBust = Math.random().toString(36).substring(2);
  const response = await fetch(filename + '?' + cacheBust);

  if (!response.ok) {
    throw new Error('Failed to load data object from CSV file.');
  }

  const text = await response.text();
  const data = text.split('\n').map(line => line.split(','));

  if (typeof data === 'string') {
    throw new Error(`Failed parsing CSV file "${filename}".`);
  }

  return data as T;
}
