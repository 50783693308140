// interface IndexedDBService {
//   setArrayBuffer(key: string, data: ArrayBuffer): Promise<void>;
//   setString(key: string, data: string): Promise<void>;
//   getArrayBuffer(key: string): Promise<ArrayBuffer | null>;
//   getString(key: string): Promise<string | null>;
// }

export async function createIndexedDBService(dbName: string, storeName: string) {
  const db = await openDatabase(dbName, storeName);

  async function openDatabase(dbName: string, storeName: string): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName);

      request.onupgradeneeded = () => {
        const db = request.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName);
        }
      };

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async function setArrayBuffer(key: string, data: ArrayBuffer): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.put(data, key);

      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  async function getArrayBuffer(key: string): Promise<ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.get(key);

      request.onsuccess = () => resolve(request.result as ArrayBuffer | null);
      request.onerror = () => reject(request.error);
    });
  }

  async function setString(key: string, data: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.put(data, key);

      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  async function getString(key: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.get(key);

      request.onsuccess = () => resolve(request.result as string | null);
      request.onerror = () => reject(request.error);
    });
  }

  return {
    setArrayBuffer,
    setString,
    getArrayBuffer,
    getString,
  };
}

export type IndexedDBService = Awaited<ReturnType<typeof createIndexedDBService>>;
