import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

const TAG_NAME = 'disclaimer-section';

@customElement(TAG_NAME)
export class DisclaimerSection extends LitElement {
  static readonly tagName = TAG_NAME;

  static styles = css`
    :host {
      display: block;
      position: relative;
      padding: 0px;
    }

    .top-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bg-background {
      background: url('/images/backgroundSubscription.webp') no-repeat center center;
      background-size: cover;
      padding-top: 50px;
    }
    .disclaimer-content {
      max-width: 1264px;
      margin: 0 auto;
    }
    .heading {
      font-size: 25px;
      font-weight: 500;
      line-height: 29.49px;
      font-family: 'Abhaya Libre', serif;
      text-align: center;
      font-style: normal;
      margin-bottom: 0;
    }
    .border-bottom {
      height: 7.48px;
      width: 42px;
      margin: auto;
      margin-top: 10px;
      background: linear-gradient(90deg, #08feb3 0%, #0f80a8 100%);
      border-radius: 100px;
    }
    .paragraph {
      text-align: center;
      font-size: 17px;
      line-height: 30.57px;
      font-weight: 300;
      margin-bottom: 48.05px;
      margin-top: 19.02px;
      font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
      word-spacing: 1.75px;
    }
    .footer {
      border-top: 1px solid #00ffc2;
      padding: 29.06px 0 27px 0;
      color: #f8f8f8;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      line-height: 18.15px;
    }

    @media (max-width: 1200px) {
      .disclaimer-content {
        max-width: 800px;
      }
    }

    @media (max-width: 800px) {
      .bg-background {
        padding-top: 20px;
      }
      .heading {
        font-size: 14px;
        font-weight: 600;
        line-height: 14.16px;
      }
      .logo {
        width: 42px;
        height: 32px;
      }
      .paragraph {
        font-size: 8px;
        font-weight: 300;
        line-height: 13.68px;
        padding: 0 40px;
        margin: 0 auto;
        margin-top: 12px;
        margin-bottom: 20px;
        letter-spacing: 0;
        word-spacing: 0.5px;
      }
      .border-bottom {
        width: 19.7px;
        height: 4.47px;
      }
      .footer {
        font-weight: 400;
        font-size: 8px;
        line-height: 9.68px;
        padding: 5px 0 21px 0;
      }
      .disclaimer-content img {
        height: 50px;
      }
    }
    @media (max-width: 500px) {
      .paragraph {
        padding: 0 25px;
      }
    }
  `;

  render() {
    return html`
      <div>
        <div class="bg-background">
          <div class="disclaimer-content">
            <div class="top-logo"><img src="/images/logo.svg" alt="Logo" class="logo" /></div>
            <div class="disclaimer-section">
              <p class="heading">Disclaimer</p>
              <p class="border-bottom"></p>
            </div>
            <p class="paragraph">
              By Signing Up At The Presence Shift You Are Affirming That You Understand That The Presence Shift Is A
              Website And App That Offers Online, Educational Experiences And Courses That Include Guided Meditations
              And Other Self-Help Resources. You Also Affirm That You Understand That The Presence Shift Is Not
              Psychotherapy Or A Substitute For Any Form Of Therapy, And That You Will Not Have Any Direct Interaction
              With A Mental Health Professional For Mental Health Treatment Through This Website.
            </p>
          </div>
          <div class="footer">&#169; 2024 The Presence Shift. All Rights Reserved.</div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: DisclaimerSection;
  }
}
