import { appContext } from '@/context/appContext';
import { processUserEmotionViaAI } from '@/tools/processUserEmotionViaAI';

export function createAnswerProcessorDictionary() {
  type PossiblyPromise<T> = T | Promise<T>;
  type Updates = Record<string, string | null>;
  type ProcessorResult = PossiblyPromise<Updates | void>;
  type Processors = Record<string, (input: string | null, context: Record<string, string | null>) => ProcessorResult>;

  const cleanUpString = (input: string) => {
    input = input.trim();
    input = input.toLowerCase();
    if (input === '') {
      throw new Error('Empty string');
    }
    return input;
  };

  const processors = {
    shifter_name: input => {
      const rawShifterName = input;

      if (input === '' || input === null) {
        appContext.userData.profile.proxy.name = null;
        appContext.userData.answers.proxy.name = null;

        return {
          shifter_name: null,
          shifter_name_raw: '',
        };
      }

      input = cleanUpString(input);

      //// Strip out all instance of "hi", "hey", "hello", "there", "i'm", "i am", "my name is"
      input = input.replace(/\b(it is|it's|hi|hey|hello|there|i'm|i am|my name is|my name's)\b/g, '');

      //// Strip to only alphanumeric characters and spaces
      input = input.replace(/[^a-zA-Z'0-9\s]/g, '');

      //// Make sure to capitalize the first letter in each word
      input = input.replace(/\b(\w)/g, m => m.toUpperCase());

      //// do the 11labs thing and store the result in the browser's indexedDB
      appContext.aiVoice?.generateAudioAndSaveToDB(input).catch(error => {
        console.error('🎤 Failed to generate audio for name:', input, error);
      });

      //// save to firestore
      appContext.userData.profile.proxy.name = input;
      appContext.userData.answers.proxy.name = input;

      return {
        shifter_name: input,
        shifter_name_raw: rawShifterName,
      };
    },

    shifter_age: input => {
      if (input === null) {
        return;
      }

      //// Strip to numeric characters
      input = input.replace(/[^0-9]/g, '');

      if (input === '') {
        return;
      }

      //// save to firestore
      appContext.userData.profile.proxy.age = +input;
      appContext.userData.answers.proxy.age = input;

      return {
        shifter_age: input,
      };
    },

    feeling_before_shift_1: (input, vars) => {
      if (input === null) {
        input = '';
      }

      input = cleanUpString(input);

      const emotionRecognized = appContext.emotionsProcessor.isEmotionRecognized(input);

      if (emotionRecognized) {
        appContext.aiVoice?.generateAudioAndSaveToDB(input);
        return {
          feeling_before_shift_1_tries: 0,
          feeling_before_shift_1_unrecognized: null,
          feeling_before_shift_1: input,
        };
      } else {
        const currentTries = +(vars.feeling_before_shift_1_tries ?? 0);
        return {
          feeling_before_shift_1: null,
          feeling_before_shift_1_unrecognized: input || null,
          feeling_before_shift_1_tries: String(currentTries + 1),
        };
      }
    },

    ai_feeling_before_shift_1: async input => {
      if (input === null) {
        return;
      }

      input = cleanUpString(input);

      const { emotionsProcessor } = appContext;
      const alreadyRecognized = emotionsProcessor.isEmotionRecognized(input);
      const recognizableEmotions = emotionsProcessor.getEmotionsList();
      const result = await processUserEmotionViaAI(input, recognizableEmotions);
      if (!result) {
        console.log("🧠 AI could not classify the user's emotion");
        return;
      }

      console.log(`🧠 AI classified the user\'s emotion (${input}) as:`, result);
      return {
        ai_feeling_before_shift_1_raw: input,
        ai_feeling_before_shift_1: alreadyRecognized ? input : result.emotion,
        ai_feeling_before_shift_1_category: result.category,
        ai_feeling_before_shift_1_sentiment: result.sentiment,
      };
    },

    ai_feeling_after_shift_1: async input => {
      if (input === null) {
        return;
      }

      input = cleanUpString(input);

      const { emotionsProcessor } = appContext;
      const alreadyRecognized = emotionsProcessor.isEmotionRecognized(input);
      const recognizableEmotions = emotionsProcessor.getEmotionsList();
      const result = await processUserEmotionViaAI(input, recognizableEmotions);
      if (!result) {
        console.log("🧠 AI could not classify the user's emotion");
        return;
      }

      console.log(`🧠 AI classified the user\'s emotion (${input}) as:`, result);
      return {
        ai_feeling_after_shift_1_raw: input,
        ai_feeling_after_shift_1: alreadyRecognized ? input : result.emotion,
        ai_feeling_after_shift_1_category: result.category,
        ai_feeling_after_shift_1_sentiment: result.sentiment,
      };
    },
  } as Processors;

  return processors;
}

export type AnswerProcessorDictionary = ReturnType<typeof createAnswerProcessorDictionary>;
