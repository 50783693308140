import * as THREE from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

const regex = /.*?Dolphin_rig_v\d+(.+)/;

// const newPrefix = 'Dolphin_';
// const newPrefix = 'Dolphin_rig_v003:';
const newPrefix = 'Dolphin_rig_v003';
// const newPrefix = 'Transform3_2merged:Dolphin_rig_v003:';

const failedLoads = new Set<string>();

function fileNameListToUrls(fileNameList: string) {
  return fileNameList
    .split('\n')
    .map(line => line.trim())
    .filter(line => !line.startsWith('//'))
    .map(line => line.replace("'", '').replace("'", ''))
    .filter(Boolean);
}

async function loadFBXs(fbxFileListString: string) {
  const fbxFileNames = fileNameListToUrls(fbxFileListString);
  console.log('🎤 FBX URLs:', fbxFileNames);

  const fbxs = (
    await Promise.allSettled(
      fbxFileNames.map(async fileName => {
        const url = `/.ignored-assets/fbx/${fileName}`;

        const loader = new FBXLoader();
        const fbx = await loader.loadAsync(url).catch(e => {
          failedLoads.add(fileName);
          console.error('🎤 Failed to load FBX:', fileName, e);
          return null;
        });
        return fbx;
      })
    )
  )
    .map(result => {
      if (result.status === 'fulfilled') {
        return result.value;
      } else {
        return null;
      }
    })
    .filter(Boolean) as THREE.Group[];

  console.log('🎤 FBXs:', fbxs.length);

  return fbxs;
}

async function loadGLBs(glbFileListString: string) {
  const glbFileNames = fileNameListToUrls(glbFileListString);
  console.log('🎤 GLB URLs:', glbFileNames);

  const glbs = (
    await Promise.allSettled(
      glbFileNames.map(async fileName => {
        const url = `/.ignored-assets/glb/${fileName}`;

        const loader = new GLTFLoader();
        const glb = await loader.loadAsync(url).catch(e => console.error('🎤 Failed to load GLB:', fileName, e));
        return glb;
      })
    )
  )
    .map(result => {
      if (result.status === 'fulfilled') {
        return result.value;
      } else {
        return null;
      }
    })
    .filter(Boolean) as GLTF[];

  console.log('🎤 GLBs:', glbs.length);

  return glbs;
}

export async function loadModelsCombineAnimationsAndExportFile() {
  const fbxs = await loadFBXs(`
   1st_Tranformation_Penguin.fbx
  'Breathing (1).fbx'
   Breathing.fbx
   Emerge.fbx
   Encounter_Dolphin1.fbx
  'Encounter_Dolphin2 (1).fbx'
   Encounter_Dolphin2.fbx
   Encounter_Penguine.fbx
  'Intro for GIF making.fbx'
   Loop.fbx
  'Nodding (correct namespace).fbx'
  'Penguin Encounter _ Penguin (1).fbx'
  'Waiting (2).fbx'
  `);
  const glbs = await loadGLBs(`
  //  Dolphin.glb
   Dolphin SMALL.glb
   Dolphinia.glb
  `);
  const models = [...fbxs, ...glbs];

  const animations = [] as THREE.AnimationClip[];
  for (const model of models) {
    animations.push(...model.animations);
  }

  // const mainModel = fbxs[0];
  const mainModel = glbs[0].scene;
  mainModel.animations = animations;

  cleanupObject(mainModel);
  fixObjectNodeNames(mainModel, newPrefix);

  console.log('❌ Failed to load:', [...failedLoads]);
  console.log('🎤 Main Model:', mainModel);

  window.addEventListener('click', () => {
    const filename = newPrefix.replace(/:/g, '');
    exportGLB(mainModel, filename);
  });
}

function cleanupObject(fbx: THREE.Group) {
  const lines = [] as THREE.Line[];
  fbx.traverse(obj => {
    if (obj instanceof THREE.Line) {
      lines.push(obj);
    }
  });
  for (const line of lines) {
    //// replace the line with an empty group
    const parent = line.parent!;
    const group = new THREE.Group();
    group.uuid = line.uuid;
    group.name = line.name;

    if (line.children.length) {
      group.add(...line.children);
    }

    parent.add(group);
    parent.remove(line);
  }
}

function fixObjectNodeNames(fbx: THREE.Group, newPrefix: string) {
  fbx.traverse(obj => {
    const newName = obj.name.replace(regex, newPrefix + '$1');
    obj.name = newName;
  });

  for (const [i, animation] of fbx.animations.entries()) {
    const numPrefix = i.toString().padStart(2, '0');
    animation.name = `A${numPrefix}|${animation.name}`;

    for (const track of animation.tracks) {
      const newName = track.name.replace(regex, newPrefix + '$1');
      track.name = newName;
    }
  }
}

export async function exportGLB(scene: THREE.Group, filename: string) {
  console.log('🎤 Exporting GLB:', filename, 'with', scene.animations.length, 'animations');

  const exporter = new GLTFExporter();
  const result = await exporter.parseAsync(scene, {
    binary: true,
    animations: scene.animations,
    onlyVisible: true,
    forceIndices: true,
  });

  console.log('🎤 GLB export result:', result);

  if (result instanceof ArrayBuffer) {
    saveArrayBuffer(result, filename + '.glb');
  } else {
    const output = JSON.stringify(result, null, 2);
    console.log(output);
    saveString(output, filename + '.gltf');
  }
}

function saveString(text: string, filename: string): void {
  save(new Blob([text], { type: 'text/plain' }), filename);
}

function saveArrayBuffer(buffer: ArrayBuffer, filename: string): void {
  save(new Blob([buffer], { type: 'application/octet-stream' }), filename);
}

function save(blob: Blob, filename: string): void {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}
