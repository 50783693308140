import YAML from 'yaml';

export async function loadMockScriptData(filename: string) {
  const cacheBust = Math.random().toString(36).substring(2);
  const response = await fetch(filename + '?' + cacheBust);

  console.log(`🧪 Loading mock script data:`, filename, response);

  const text = await response.text();
  const data = YAML.parse(text);

  if (typeof data === 'string') {
    throw new Error(`Failed parsing YAML script.`);
  }

  return data;
}
