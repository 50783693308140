import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('glass-button')
export class GlassButton extends LitElement {
  static styles = css`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button.glass-button {
      color: white;
      font-family: Inter;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      width: 320px;
      max-width: 80%;
      min-width: fit-content;
      display: flex;
      padding: 16px 64px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border: none;
      border-radius: 120px;
      background: linear-gradient(6deg, rgba(0, 0, 0, 0.18) 3.12%, rgba(0, 255, 209, 0.14) 95.05%);
      box-shadow:
        0px 2px 12px 0px rgba(67, 255, 187, 0.72) inset,
        0px 6px 8px 0px rgba(255, 255, 255, 0.12) inset;
      backdrop-filter: blur(15px);

      transition-duration: 0.2s;
      transition-property: color, background, box-shadow;

      pointer-events: all;
      cursor: pointer;

      text-decoration: none;
    }

    button.glass-button:hover {
      color: cyan;
      background: linear-gradient(6deg, rgba(0, 0, 0, 0.28) 3.12%, rgba(0, 255, 209, 0.24) 95.05%);
      box-shadow:
        0px 2px 12px 0px rgba(67, 255, 187, 0.92) inset,
        0px 6px 8px 0px rgba(255, 255, 255, 0.22) inset;
    }
  `;

  @property({ type: String }) type = 'button';
  @property({ type: Boolean }) loading = false;

  render() {
    return html`
      <button type=${this.type} @click=${this.onClick} class="glass-button">
        ${this.loading ? html`<small-spinner></small-spinner>` : html`<slot></slot>`}
      </button>
    `;
  }

  onClick() {
    if (this.type === 'submit') {
      // this.dispatchEvent(new Event('submit', { bubbles: true, composed: true }));
    } else {
      // this.dispatchEvent(new CustomEvent('click'));
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'glass-button': GlassButton;
  }
}
