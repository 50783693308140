import { appContext } from '@/context/appContext';
import { ShiftContext } from '@/context/shiftContext';

import { createCharacterAnimationController } from '@/controllers/createCharacterAnimationController';
import { ModelNodeNames } from '@/scene/constants/ModelNodeNames';
import { createModelPartObjectByNameProxy } from '@/scene/createModelPartObjectByNameProxy';

export async function initializeDevThingsForShift(shiftContext: ShiftContext) {
  const { gltfs } = shiftContext;
  const { addEnterFrameCallback } = shiftContext.sceneContext! || {};

  Object.assign(globalThis, {
    shiftContext,
    d1: {
      scene: gltfs!.dolphin1.scene,
      animations: gltfs!.dolphin1.animations,
      parts: createModelPartObjectByNameProxy(gltfs!.dolphin1.scene, ModelNodeNames.Dolphin1),
      // ctrl: createCharacterAnimationController(gltfs!.dolphin1, addEnterFrameCallback),
    },
    d2: {
      scene: gltfs!.dolphin2.scene,
      animations: gltfs!.dolphin2.animations,
      parts: createModelPartObjectByNameProxy(gltfs!.dolphin2.scene, ModelNodeNames.Dolphin2),
      // ctrl: createCharacterAnimationController(gltfs!.dolphin2, addEnterFrameCallback),
    },
    gltfs,
    sceneCtrl: shiftContext.sceneContext?.sceneCtrl,
  });

  //// Ultra-fast-forward to script item index
  const ultraFastForwardingTarget = appContext.urlParams.ffTo;
  if (ultraFastForwardingTarget !== undefined) {
    const isTag = Number.isNaN(Number(ultraFastForwardingTarget));
    if (isTag) {
      ultraFastForwardToScriptItemTag(String(ultraFastForwardingTarget), shiftContext);
    } else {
      ultraFastForwardToScriptItemIndex(+ultraFastForwardingTarget, shiftContext);
    }
  }
}

function ultraFastForwardToScriptItemIndex(targetScriptItemIndex: number, shiftContext: ShiftContext) {
  if (isNaN(targetScriptItemIndex)) return;

  if (targetScriptItemIndex <= 0) return;

  console.log(`🐬 Ultra-fast-forwarding to script item index: ${targetScriptItemIndex}`);

  const { playerCtrl, events } = shiftContext;

  const REASON_TO_SKIP = 'ultra-fast-forward-in-progress';
  const stopSkippingTTC = playerCtrl.reasonsToSkipWaitingForUserTap.add(REASON_TO_SKIP);
  const stopSkippingINPUT = playerCtrl.reasonsToSkipWaitingForUserInput.add(REASON_TO_SKIP);

  const SUPER_FF_SPEED = 99;
  const timeScaleMultiplier = appContext.animCtrl.timeScale.addMultiplier(SUPER_FF_SPEED);

  const offScriptItemStarted = events.playScriptItemStarted.on((_, scriptItemIndex) => {
    if (scriptItemIndex < targetScriptItemIndex) return;

    console.log(`🐬 Stopping fast forwarding to script item index: ${targetScriptItemIndex}`);

    timeScaleMultiplier.remove();

    stopSkippingTTC();
    stopSkippingINPUT();

    offScriptItemStarted();
  });
}

function ultraFastForwardToScriptItemTag(targetScriptItemTag: string, shiftContext: ShiftContext) {
  console.log(`🐬 Ultra-fast-forwarding to script item tag: ${targetScriptItemTag}`);

  const { playerCtrl, events } = shiftContext;

  const REASON_TO_SKIP = 'ultra-fast-forward-in-progress';
  const stopSkippingTTC = playerCtrl.reasonsToSkipWaitingForUserTap.add(REASON_TO_SKIP);
  const stopSkippingINPUT = playerCtrl.reasonsToSkipWaitingForUserInput.add(REASON_TO_SKIP);

  const SUPER_FF_SPEED = 99;
  const timeScaleMultiplier = appContext.animCtrl.timeScale.addMultiplier(SUPER_FF_SPEED);

  const offScriptItemStarted = events.playScriptItemStarted.on(scriptItem => {
    if (scriptItem.tag !== targetScriptItemTag) return;

    console.log(`🐬 Ultra-fast-forwarding reached script item tag: ${targetScriptItemTag}`);

    timeScaleMultiplier.remove();

    stopSkippingTTC();
    stopSkippingINPUT();

    offScriptItemStarted();
  });
}
