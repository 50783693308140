import { loadMockScriptData } from '@/mock/loadMockSequenceScriptData';
import { loadScriptDataFromFirebase } from '@/backend/loadScriptDataFromFirebase';

export async function loadRealOrMockScriptData(scriptSlug: string, useLocalHardCodedYAML: boolean = false) {
  if (useLocalHardCodedYAML) {
    const cacheBust = Math.random().toString(36).substring(2);
    const yamlUrl = `/mock/scripts/${scriptSlug}.script.yaml?${cacheBust}`;

    console.log('🧪 Loading mock script data:', yamlUrl);

    const mockSequenceScriptData = await loadMockScriptData(yamlUrl);

    return {
      id: scriptSlug,
      name: `mockSequenceScriptData`,
      script: mockSequenceScriptData as SequenceScript,
    };
  } else {
    return await loadScriptDataFromFirebase(scriptSlug);
  }
}
