import { gsap } from 'gsap';

import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

import { appContext } from '@/context/appContext';

const FORCE_DISABLE_BLOOM = false;

export function createThreeScene(options: { enableBloom?: boolean }) {
  const scene = new THREE.Scene();
  const renderer = new THREE.WebGLRenderer();

  const cameraHolder = createHandheldCamera();
  scene.add(cameraHolder);

  const composer =
    options.enableBloom && !FORCE_DISABLE_BLOOM ? createBloomPass(scene, cameraHolder.camera, renderer) : null;

  addFog(scene);

  const lights = addLightingAndBackgroundColor(scene);

  if (appContext.urlParams.orbital) {
    import('three/examples/jsm/controls/OrbitControls.js').then(({ OrbitControls }) => {
      const controls = new OrbitControls(cameraHolder.camera, renderer.domElement);
      controls.update();
    });
  }

  let requestAnimationFrameHandle = -1;

  let renderingEnabled = true;

  return {
    scene,
    camera: cameraHolder.camera,
    cameraHolder,
    renderer,
    lights,
    startFrameLoop: (elementToCopySizeFrom: Element) => {
      const container = elementToCopySizeFrom;

      const { lowRes } = appContext.urlParams;

      function updateSize() {
        const width = container?.clientWidth ?? 101;
        const height = container?.clientHeight ?? 101;

        cameraHolder.camera.aspect = width / height;
        cameraHolder.camera.updateProjectionMatrix();

        renderer.setSize(width, height);
        composer?.setSize(width, height);

        renderer.setPixelRatio(window.devicePixelRatio / lowRes);
        composer?.setPixelRatio(window.devicePixelRatio / lowRes);
      }

      const animate = function () {
        if (renderingEnabled) {
          updateSize();

          if (composer) {
            composer.render();
          } else {
            renderer.render(scene, cameraHolder.camera);
          }

          cameraHolder.advanceTime(1 / 60);
        }

        requestAnimationFrameHandle = requestAnimationFrame(animate);
      };

      animate();

      return () => {
        cancelAnimationFrame(requestAnimationFrameHandle);
      };
    },

    tweenCameraTo: (endPosition: [number, number, number], duration: number = 1) => {
      return gsap.to(cameraHolder.position, {
        // Tween the camera group instead of the camera
        duration,
        x: endPosition[0],
        y: endPosition[1],
        z: endPosition[2],
        onUpdate: () => cameraHolder.camera.updateProjectionMatrix(),
      });
    },

    tweenCameraFrom: (startPosition: [number, number, number], duration: number = 1) => {
      const originalPosition = new THREE.Vector3().copy(cameraHolder.position); // Copy the camera group's position
      cameraHolder.position.set(startPosition[0], startPosition[1], startPosition[2]); // Set the camera group's position
      return gsap.to(cameraHolder.position, {
        // Tween the camera group instead of the camera
        duration,
        x: originalPosition.x,
        y: originalPosition.y,
        z: originalPosition.z,
        onUpdate: () => cameraHolder.camera.updateProjectionMatrix(),
        ease: 'power.out',
      });
    },

    setRenderingEnabled(enabled: boolean) {
      renderingEnabled = enabled;
    },
    getRenderingEnabled() {
      return renderingEnabled;
    },
  };
}

function createHandheldCamera() {
  const camera = new THREE.PerspectiveCamera(50, 1, 1, 13000);
  camera.name = 'Camera';
  camera.aspect = 1;

  let cameraBaseY = 0;
  let time = 0;
  const cameraHolder = Object.assign(new THREE.Group(), {
    name: 'CameraHolder',
    camera,
    advanceTime(delta: number) {
      //// advance time and update camera position with a slow breathing effect
      time += delta;
      const breathing = 10 * Math.sin(time * 0.8);
      camera.position.y = cameraBaseY + breathing;
    },
  });
  cameraHolder.add(camera);

  appContext.orientationTracker.addListener(({ isLandscape, ratio }) => {
    camera.fov = isLandscape ? 50 : 60;

    cameraBaseY = isLandscape ? 0 : -130;
    camera.position.z = (1 / ratio) * 400;
    // camera.position.z = isLandscape ? 0 : 200;
  }, true);

  return cameraHolder;
}

function createBloomPass(scene: THREE.Scene, camera: THREE.Camera, renderer: THREE.WebGLRenderer) {
  const composer = new EffectComposer(renderer);

  const renderPass = new RenderPass(scene, camera);
  composer.addPass(renderPass);

  const size = new THREE.Vector2(window.innerWidth, window.innerHeight);
  const bloomPass = new UnrealBloomPass(size, 0.7, 0.5, 0.5);
  composer.addPass(bloomPass);

  return composer;
}

function addFog(scene: THREE.Scene) {
  const color = 0x042635;
  const near = 0;
  const far = 10000;
  scene.fog = new THREE.Fog(color, near, far);
}

function addLightingAndBackgroundColor(scene: THREE.Scene) {
  scene.background = new THREE.Color(0x005b6e); // Purple color

  const ambientLight = new THREE.AmbientLight(0x3090f0, 0.99);
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 2.5);
  directionalLight.position.set(0, 1, 1);
  scene.add(directionalLight);

  const spotlight = createStageSpotlight();
  scene.add(spotlight);

  return { ambientLight, directionalLight, spotlight };
}

function createStageSpotlight() {
  const spotLight = new THREE.SpotLight(0x306060, 19, 0, Math.PI / 4, 0.8, 0.0);
  spotLight.position.set(0, 300, 0);
  spotLight.target.position.set(0, 0, 0);
  return spotLight;
}
