import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const TAG_NAME = 'tos-footer';

@customElement(TAG_NAME)
export class TOSFooter extends LitElement {
  static readonly tagName = TAG_NAME;

  // @property({ type: Boolean }) checkbox = false;

  @property({ type: String }) verb = 'signing up';

  static readonly styles = css`
    :host {
      color: #aaa;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.16px;
    }

    :host a {
      color: #fff;
    }
  `;

  render() {
    return html`
      By ${this.verb}, you agree to the
      <a id="terms-of-use-link" href="/privacy-policy.html" class="link" target="_blank">Terms of Use</a>, <br />
      <a id="privacy-notice-link" href="/privacy-policy.html" class="link" target="_blank">Privacy Notice</a>, and
      <a id="cookie-notice-link" href="/privacy-policy.html" class="link" target="_blank">Cookie Notice</a>.
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: TOSFooter;
  }
}
