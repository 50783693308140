import gsap from 'gsap';

import { LitElement, html, css } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

import SlDialog from '@shoelace-style/shoelace/dist/components/dialog/dialog.js';

import { appContext } from '@/context/appContext';

import '@shoelace-style/shoelace/dist/components/checkbox/checkbox.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/radio-button/radio-button.js';
import '@shoelace-style/shoelace/dist/components/radio/radio.js';
import '@shoelace-style/shoelace/dist/components/switch/switch.js';
import '@shoelace-style/shoelace/dist/components/range/range.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/themes/dark.css';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';

type SettingsValues = {
  shifterName: string;
  autoAdvanceScript: boolean;
  fastforwardSpeed: number;
};

@customElement('settings-button')
class SettingsButton extends LitElement {
  static styles = css`
    #menu-button {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      svg {
        width: 48px;
        height: 48px;
        fill: #fff3;
      }

      z-index: 900900;
    }
  `;

  render() {
    return html`
      <div id="menu-button" @click=${this._onClick} class="settings-button sl-theme-dark">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="#fff3" viewBox="0 0 24 24">
          <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
        </svg>
      </div>
    `;
  }

  private _onClick() {
    this.dispatchEvent(new CustomEvent('settings-button-clicked'));
  }
}

@customElement('settings-dialog')
class SettingsDialog extends LitElement {
  static styles = css`
    .dialog__panel {
      background: rgba(0, 0, 0, 0.8);
    }
  `;

  @property({ type: Object })
  private settingsValues: SettingsValues = {} as SettingsValues;

  @property({ type: Boolean })
  private settingsLoaded = false;

  @query('sl-dialog') dialog!: SlDialog;

  render() {
    const { shifterName, autoAdvanceScript, fastforwardSpeed } = this.settingsValues;

    console.log('render', this.settingsValues);

    return html`
      <sl-dialog label="The Presence Shift" class="settings-dialog sl-theme-dark">
        <p><i>Nice to meet you!</i></p>
        <sl-input
          help-text="What would you like us to call you?"
          value=${shifterName ?? ''}
          @input=${(e: any) => this.updateSettings({ shifterName: e.target.value })}
          .disabled=${!this.settingsLoaded}
        ></sl-input>

        <sl-divider></sl-divider>

        <p>
          <i>Experience <b>The Presence Shift</b> at your own pace!</i>
        </p>

        <input
          type="radio"
          id="radio_tapToContinue"
          name="experience"
          value="tapToContinue"
          @change=${() => this.updateSettings({ autoAdvanceScript: false })}
          .checked=${!autoAdvanceScript}
          .disabled=${!this.settingsLoaded}
        />
        <label for="radio_tapToContinue">Tap to continue</label><br />
        <input
          type="radio"
          id="radio_autoAdvance"
          name="experience"
          value="autoAdvance"
          @change=${() => this.updateSettings({ autoAdvanceScript: true })}
          .checked=${autoAdvanceScript}
          .disabled=${!this.settingsLoaded}
        />
        <label for="radio_autoAdvance">Auto-advance</label>

        <sl-divider></sl-divider>

        <sl-button variant="primary" id="save-preferences-button" @click=${() => this.dialog.hide()}
          >All done!</sl-button
        >
      </sl-dialog>
    `;
  }

  async getSettingsValues() {
    return {
      shifterName: await appContext.userData.profile.getFieldValue('name'),
      autoAdvanceScript: appContext.userPrefs.autoAdvanceScript,
      fastforwardSpeed: appContext.userPrefs.fastforwardSpeed,
    };
  }

  updateSettings(updates: Partial<SettingsValues>) {
    if (updates.shifterName !== undefined) {
      appContext.userData.profile.setFieldValue('name', updates.shifterName === '' ? null : updates.shifterName);
    }

    if (updates.autoAdvanceScript !== undefined) {
      appContext.userPrefs.autoAdvanceScript = updates.autoAdvanceScript;
    }

    if (updates.fastforwardSpeed !== undefined) {
      appContext.userPrefs.fastforwardSpeed = updates.fastforwardSpeed;
    }
  }

  firstUpdated() {
    this.classList.add('sl-theme-dark');

    this.getSettingsValues().then(values => {
      this.settingsValues = {
        shifterName: values.shifterName ?? '',
        autoAdvanceScript: values.autoAdvanceScript,
        fastforwardSpeed: values.fastforwardSpeed,
      };
      this.settingsLoaded = true;
    });
  }
}

export function addSettingsButtonAndDialog(parentElement: HTMLElement) {
  const settingsButton = document.createElement('settings-button') as SettingsButton;
  const settingsDialog = document.createElement('settings-dialog') as SettingsDialog;

  settingsButton.addEventListener('settings-button-clicked', () => {
    settingsDialog.dialog.show();
  });

  return {
    settingsButton,
    settingsDialog,
    async fadeIn(fadeDuration: number) {
      parentElement.appendChild(settingsButton);
      parentElement.appendChild(settingsDialog);
      await gsap.from(settingsButton, { opacity: 0, duration: fadeDuration });
    },
    async fadeOutAndRemove(fadeDuration: number) {
      settingsDialog.remove();

      await gsap.to(settingsButton, { opacity: 0, duration: fadeDuration });
      settingsButton.remove();
    },
  };
}
