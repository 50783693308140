type StorageKey = string;

export function createLocalStorageDataObjectProxy<T extends { [prop: string]: any; [prop: symbol]: never }>(
  storageKey: StorageKey,
  defaultSettings: T
) {
  const userDefinedValues = (loadJSONFromLocalStorage(storageKey) ?? {}) as Partial<T>;

  function updateLocalStorage() {
    const newJsonString = JSON.stringify(userDefinedValues);
    localStorage.setItem(storageKey, newJsonString);
  }

  return new Proxy({} as T, {
    get(_, prop) {
      if (prop in userDefinedValues) {
        return userDefinedValues[prop];
      }

      return defaultSettings[prop];
    },

    set(_: T, prop, value: T[keyof T]) {
      userDefinedValues[prop as keyof T] = value;
      updateLocalStorage();

      console.log('set', prop, value);

      return true;
    },

    deleteProperty(_: T, prop) {
      delete userDefinedValues[prop];
      updateLocalStorage();

      return true;
    },

    has(_, prop) {
      return prop in userDefinedValues || prop in defaultSettings;
    },

    ownKeys(_) {
      return Array.from(new Set(([] as string[]).concat(Object.keys(userDefinedValues), Object.keys(defaultSettings))));
    },

    getOwnPropertyDescriptor(_, prop) {
      return (
        Object.getOwnPropertyDescriptor(userDefinedValues, prop) ??
        Object.getOwnPropertyDescriptor(defaultSettings, prop)
      );
    },

    defineProperty(_: T, prop, descriptor) {
      Object.defineProperty(userDefinedValues, prop, descriptor);
      updateLocalStorage();

      return true;
    },
  });
}

function loadJSONFromLocalStorage<T>(storageKey: StorageKey): T | null {
  const settings = localStorage.getItem(storageKey);
  return settings ? JSON.parse(settings) : null;
}
