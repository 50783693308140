import { checkUserSignedInNotAnonymously } from '@/backend/ensureUserSignedIn';
import { buildVars } from '@/constants/buildVars';
import { appContext } from '@/context/appContext';
import { IntroVideo } from '@/html/components/IntroVideo';
import { LandingSplashScreen } from '@/html/components/LandingSplashScreen';
import { ConversionReporting } from '@/tools/gtag/gtagReportConversion';
import { BoomerangVideoBackground } from './components/BoomerangVideoBackground';
import { WelcomeSplashScreen } from './components/WelcomeSplashScreen';
import { displaySigningSplash } from './displaySigningSplash';
import { displayErrorMessage } from './system/displayErrorMessage';

import '@/html/gui/SkinPickButton';
import '@/html/playback/ShifterInputPickSkin';

export async function displayWelcomeScreenAndIntroVideo(parentElement: HTMLElement) {
  const errorCleanUpFunctions = [] as (() => void)[];

  const boomerangVideoBackground = new BoomerangVideoBackground();
  parentElement.appendChild(boomerangVideoBackground);
  errorCleanUpFunctions.push(() => boomerangVideoBackground.remove());

  try {
    if (buildVars.gates.readyScr) {
      const readySplash = new LandingSplashScreen();
      parentElement.appendChild(readySplash);
      errorCleanUpFunctions.push(() => readySplash.remove());
      readySplash.fadeIn(2);
      await readySplash.waitUntilDismissed();
      readySplash.remove();
    }

    ConversionReporting.reportConversion('pass_landing_screen');
    appContext.events.userConversionCheckpoint.emit('pass_landing_screen');

    async function signInMaybe() {
      if (!buildVars.gates.signIn) return;

      const isLoggedIn = await checkUserSignedInNotAnonymously();
      if (isLoggedIn) return;

      if (0) {
        const { IntroductionSplashScreen } = await import('@/html/components/IntroductionSplashScreen');
        const introductionSplash = new IntroductionSplashScreen();
        parentElement.appendChild(introductionSplash);
        await introductionSplash.fadeIn(0.5);
        await introductionSplash.waitUntilDismissed();
        await introductionSplash.fadeOutAndRemove(0.5);
      }

      if (buildVars.gates.introScr) {
        const { WebScreen } = await import('@/html/components/WebScreen');
        const newIntroductionSplash = new WebScreen();
        parentElement.appendChild(newIntroductionSplash);
        await newIntroductionSplash.waitUntilSignUpClicked();
        newIntroductionSplash.remove();
      }

      await displaySigningSplash(parentElement);
    }

    await signInMaybe();

    if (buildVars.gates.welcomeScr) {
      const welcomeSplash = new WelcomeSplashScreen();
      parentElement.appendChild(welcomeSplash);
      errorCleanUpFunctions.push(() => welcomeSplash.remove());
      welcomeSplash.fadeIn(2);
      await welcomeSplash.waitUntilDismissed();
      welcomeSplash.remove();
    }

    ConversionReporting.reportConversion('pass_begin_screen');
    appContext.events.userConversionCheckpoint.emit('pass_begin_screen');

    const introVid = new IntroVideo();
    parentElement.appendChild(introVid);
    errorCleanUpFunctions.push(() => introVid.remove());
    await introVid.updateComplete;

    const { playAwaitable, firstTimeUpdateAwaitable, sevenSecondsMarkAwaitable } = introVid.playMedia([
      '/video/IntroSq.webm',
      '/video/IntroSq.mp4',
    ]);

    introVid.videoElement.hidden = true;
    await playAwaitable;
    await firstTimeUpdateAwaitable;
    introVid.videoElement.hidden = false;

    await introVid.fadeIn(1.5);
    boomerangVideoBackground.remove();

    await sevenSecondsMarkAwaitable;
    introVid.fadeOutAndRemove(1.5);

    ConversionReporting.reportConversion('pass_intro_video');
    appContext.events.userConversionCheckpoint.emit('pass_intro_video');
  } catch (error) {
    console.error(error);
    errorCleanUpFunctions.forEach(fn => fn());
    await displayErrorMessage(String(error), 'Something went wrong.');
  }
}
