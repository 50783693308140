import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('age-confirmation-checkbox')
export class AgeConfirmationCheckbox extends LitElement {
  static styles = css`
    .age-confirmation-checkbox-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-top: 40px;
      margin-left: 20px;
      margin-right: 20px;

      cursor: pointer;

      font-size: 14px;
    }

    .age-confirmation-checkbox-container input[type='checkbox'] {
      margin-right: 10px;
    }
  `;

  @property({ type: Number })
  age = 13;

  @property({ type: Boolean })
  checked = false;

  render() {
    return html`
      <div class="age-confirmation-checkbox-container">
        <input
          type="checkbox"
          id="age-confirmation-checkbox"
          name="age-confirmation-checkbox"
          .checked=${this.checked}
          @change=${this.handleChange}
        />
        <label for="age-confirmation-checkbox" id="age-confirmation-checkbox-label">
          I am over ${this.age} years old and understand this is not mental health treatment.
        </label>
      </div>
    `;
  }

  handleChange(e: Event) {
    this.checked = (e.target as HTMLInputElement).checked;
    this.dispatchEvent(
      new CustomEvent('change', {
        detail: { checked: this.checked },
        bubbles: true,
        composed: true,
      })
    );
  }

  isChecked() {
    return this.checked;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'age-confirmation-checkbox': AgeConfirmationCheckbox;
  }
}
