import '@/html/gui/SkinPickButton';
import '@/html/playback/ShifterInputPickSkin';
import { MusicTracks } from '../audio/music';
import { playShiftScript } from '@/animation/playShiftScript';
import { checkUserSignedInNotAnonymously, ensureUserAuthenticated } from '@/backend/ensureUserSignedIn';
import { auth } from '@/backend/firebase';
import { buildVars } from '@/constants/buildVars';
import { appContext } from '@/context/appContext';
import { createShiftContext } from '@/context/shiftContext';
import { ChatBubblesManager } from '@/controllers/ChatBubblesManager';
import { loadRealOrMockScriptData } from '@/data/loadRealOrMockScriptData';
import { addPlaybackLayoutElements } from '@/html/addPlaybackLayoutElements';
import { addSceneLayoutElements } from '@/html/addSceneLayoutElements';
import { addSettingsButtonAndDialog } from '@/html/addSettingsButtonAndDialog';
import { CircularProgressBar } from '@/html/common/CircularProgressBar';
import { BoomerangVideoBackground } from '@/html/components/BoomerangVideoBackground';
import { displayContinueWithEmail } from '@/html/displayContinueWithEmail';
import {
  displayGetNextPresenceShiftSplash,
  displayGetNextPresenceShiftSplashWithEmail,
} from '@/html/displayGetNextPresenceShiftSplash';
import {
  displayLearnMoreSplash_EndVersion,
  displayLearnMoreSplash_ShareVersion,
  displayLearnMoreSplash_SignUpVersion,
  LearnMoreSplash,
} from '@/html/displayLearnMoreSplash';
import { displayShareSplash } from '@/html/displayShareSplash';
import { displaySigningSplash } from '@/html/displaySigningSplash';
import { displayWelcomeScreenAndIntroVideo } from '@/html/displayWelcomeScreenAndIntroVideo';
import { displayErrorMessage } from '@/html/system/displayErrorMessage';
import { defaultVars } from '@/misc/defaultVars';
import { createPlayerControls } from '@/scene/createPlayerControls';
import { ensureAllAssetsLoaded } from '@/scene/ensureAllAssetsLoaded';
import { createFastForwardingInteractionManager } from '@/services/createFastForwardingInteractionManager';
import { createStripePaymentService } from '@/services/createStripePaymentService';
import { ConversionReporting } from '@/tools/gtag/gtagReportConversion';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { createIndexedDBService } from '@/util/core/createIndexedDBService';
import { delay } from '@/util/delay';
import { AnimatedBackground } from './components/AnimatedBackground';
import { produceAnimationNamesMap } from './exports/produceAnimationNamesMap';
import { loadModelsCombineAnimationsAndExportFile } from './fixFbxAnimationNames';
import { displayPlaceholderSplash } from './html/displayPlaceholderSplash';
import { displayIntroductionSplash, IntroductionSplashScreen } from '@/html/components/IntroductionSplashScreen';
import { SubscriptionScreen } from '@/html/components/SubscriptionScreen';
import { WebScreen } from '@/html/components/WebScreen';

/**
 * Alternative to `main()` function in `main.ts`.
 *
 * This function is used to run the app in a development sandbox environment.
 */
export async function runDevSandbox() {
  Object.assign(window, { __sandbox__: true, appContext, buildVars });

  const { appDiv, setMuted } = appContext;

  appDiv.style.background = 'none';

  setMuted(appContext.urlParams.mute ?? false);

  await ensureUserAuthenticated();

  // await addBlueprintBackground();

  {
    const settingsElements = addSettingsButtonAndDialog(appDiv);
    settingsElements.fadeIn(1);
  }

  const functions = await makeFunctions();
  const functionKeys =
    typeof appContext.urlParams.sandbox === 'boolean'
      ? Object.keys(functions)
      : appContext.urlParams.sandbox.split(',');
  for (const functionKey of functionKeys) {
    try {
      const func = functions[functionKey as keyof typeof functions];
      if (!func) continue;
      await func();
    } catch (error) {
      console.error('🐬 Error running sandbox function:', functionKey, error);
    }
  }

  await delay(9999);
}

async function makeFunctions() {
  const { appDiv } = appContext;
  const shifterNameSaved = await appContext.userData.profile.getFieldValue('name');
  const shifterName = shifterNameSaved ?? 'Amigo';

  const functions = {
    //// You can create any function you want here
    //// and then go to http://localhost:5173/?sandbox=<<function name>>
    //// to test your code.
    ////
    subscription: async () => {
      // addBoomerangVideoBackground();

      const screen = new SubscriptionScreen();
      appDiv.appendChild(screen);
      const plan = await screen.waitUntilPlanChosen();
      console.log('🚀 Subscription plan chosen', plan);

      screen.remove();
    },

    web_1: async () => {
      const { music } = appContext;
      addBoomerangVideoBackground();
      music.playTrack(MusicTracks.Main);
      const screen = new WebScreen();
      appDiv.appendChild(screen);
      return screen;
    },

    ////
    gtag: async () => {
      await displayPlaceholderSplash(appDiv, 'Google Analytics');
      ConversionReporting.reportConversion(`share_on_email`, window.location);
    },
    boomerang: async () => {
      const background = new BoomerangVideoBackground();
      background.style.zIndex = '-11';
      appDiv.appendChild(background);
      return background;
    },
    welcome: async () => {
      await displayWelcomeScreenAndIntroVideo(appDiv);
    },
    share: async () => {
      await displayShareSplash(appDiv, shifterName);
    },
    continueWithEmail: async () => {
      await displayContinueWithEmail(appDiv, shifterName);
    },
    learnMore_Share: async () => {
      await displayLearnMoreSplash_ShareVersion(appDiv, shifterName);
    },
    learnMore_SignUp: async () => {
      await displayLearnMoreSplash_SignUpVersion(appDiv, shifterName);
    },
    learnMore_EndScr: async () => {
      await displayLearnMoreSplash_EndVersion(appDiv, shifterName);
    },
    introduction: async () => {
      await displayIntroductionSplash(appDiv);
      // const introductionSplash = new IntroductionSplashScreen();
      // appDiv.appendChild(introductionSplash);
      // await introductionSplash.fadeIn(0.5);
      // await introductionSplash.waitUntilDismissed();
      // await introductionSplash.fadeOutAndRemove(0.5);
    },
    login: async () => {
      const isLoggedIn = await checkUserSignedInNotAnonymously();
      if (!isLoggedIn) {
        await displaySigningSplash(appDiv);
      } else {
        await displayPlaceholderSplash(appDiv, 'You are already signed in.');

        appContext.userData.authUserId = null;
        await auth.signOut();
        await displayPlaceholderSplash(appDiv, 'Signed out.');
        location.reload();
      }
    },
    stripe: async () => {
      await displayPlaceholderSplash(appDiv, "Let's test a simple $.50 payment via Stripe");

      const paymentService = createStripePaymentService('fiftyCent');
      await paymentService.performStripePayment();

      let success = false;

      while (!success) {
        success = await paymentService.checkIfUserPaidSuccessfully();

        if (success) {
          alert('💲 Payment successful! Stripe integration is working at 100%.');
        } else {
          alert('❌ Payment failed. Please try again.');
          await displayPlaceholderSplash(appDiv, 'Try again?');
        }
      }
    },
    nextPS: async () => {
      await displayGetNextPresenceShiftSplashWithEmail(appDiv, shifterName);
    },
    nextPS_phone: async () => {
      if (!appContext.userData.profile.proxy.phone) {
        await displayGetNextPresenceShiftSplash(appDiv, shifterName);
      } else {
        await displayPlaceholderSplash(appDiv, 'You already have a phone number.');
      }
    },
    progressBar: async () => {
      const progressBar = new CircularProgressBar();
      appDiv.appendChild(progressBar);
      progressBar.radius = 20;
      progressBar.progress = 0.0;
      await progressBar.updateComplete;
      await delay(0.5);
      progressBar.progress = 1;
      await delay(0.5);
    },
    fadingMusic: async () => {
      console.log('🎶 Playing music');
      const { music } = appContext;
      music.playTrack('/audio/track0.mp3');
      for (let i = 0; i < 10; i++) {
        await delay(2.5);
        music.reasonsToMute.set('test', true);
        await delay(2.5);
        music.reasonsToMute.set('test', false);
      }
    },
    fixFbx: async () => {
      await loadModelsCombineAnimationsAndExportFile();
    },
    printAnimationNames: async () => {
      const gltfs = await ensureAllAssetsLoaded();
      const hash = produceAnimationNamesMap(gltfs.dolphin1);
      console.log('🎤 Animation names:', hash);
    },
    splashes: testSplashScreens,
    playback: testBareSequencePlayback,
    layout: testPlaybackLayout,
    errors: testErrorMessage,
    sfx2: async () => {
      const speechClipUrl = 'voice-special/close-your-eyes.mp3';
      const speechAwaitable = appContext.sfx.playSoundBiteTrackable(speechClipUrl);
      speechAwaitable?.callAtTime?.(10, () => console.log('🎤 Fart done'));
      await speechAwaitable;
    },
  };

  return functions;
}

async function addBoomerangVideoBackground() {
  const { appDiv } = appContext;
  const background = new BoomerangVideoBackground();
  background.style.zIndex = '-11';
  appDiv.appendChild(background);
}

async function addBlueprintBackground() {
  const { appDiv } = appContext;
  const sceneLayoutElements = await addSceneLayoutElements(appDiv);
  const background = document.createElement(AnimatedBackground.tagName);
  sceneLayoutElements.setSceneContainerContent(background);
}

async function testSplashScreens() {
  const { appDiv } = appContext;

  const storedShifterName = await appContext.userData.profile.getFieldValue('name');
  const shifterName = storedShifterName ?? defaultVars.shifter_name;

  const isLoggedIn = await checkUserSignedInNotAnonymously();
  if (!isLoggedIn) {
    await displaySigningSplash(appDiv);
  }

  // await appContext.aiVoice.playAudioFor('Samantha!');

  // await displayGetNextPresenceShiftSplashWithEmail(appDiv, shifterName);

  {
    const lmComponent = new LearnMoreSplash();
    lmComponent.variant = 'v1';
    lmComponent.shifterName = shifterName;
    lmComponent.showSubForBeta = Boolean(buildVars.showSubscribeForBetaPaymentLink);
    lmComponent.showSubstackLink = Boolean(buildVars.showSubstackLink);
    lmComponent.showSocialSharingButtons = true;
    appDiv.append(lmComponent);
    await lmComponent.fadeIn();
    await lmComponent.onReturnLinkClick.waitUntil();
  }

  // if (!appContext.userData.profile.proxy.phone) {
  //   await displayGetNextPresenceShiftSplash(appDiv, shifterName);
  // }

  await displayWelcomeScreenAndIntroVideo(appDiv);

  await delay(9999);
}

async function testBareSequencePlayback() {
  const { appDiv, fastforwarder } = appContext;

  const sceneLayoutElements = await addSceneLayoutElements(appDiv);
  const background = document.createElement(AnimatedBackground.tagName);
  sceneLayoutElements.setSceneContainerContent(background);

  const elements = await addPlaybackLayoutElements(appDiv);

  const shiftsQueue =
    appContext.urlParams.queue === undefined ? ['dev'] : String(appContext.urlParams.queue).split(',');

  for (const shiftSlug of shiftsQueue) {
    const shiftContext = createShiftContext(null, elements, shiftSlug);
    Object.assign(globalThis, { appContext, shiftContext });

    const scriptDataLoadingPromise = loadRealOrMockScriptData(shiftSlug, appContext.urlParams.localScripts);
    const scriptData = await scriptDataLoadingPromise;

    const disposeFastForwardingInteractionManager = createFastForwardingInteractionManager(
      [elements.fastforwardingHitArea],
      fastforwarder
    );

    await playShiftScript(scriptData!.script, shiftContext);

    console.log('🎬 Script finished!');

    shiftContext.dispose();

    disposeFastForwardingInteractionManager();
  }
}

async function testPlaybackLayout() {
  const { appDiv } = appContext;

  const customAwaitable = createCustomAwaitable();

  //// Fake Scene
  ////
  const sceneElements = await addSceneLayoutElements(appDiv);
  const background = document.createElement(AnimatedBackground.tagName);
  sceneElements.setSceneContainerContent(background);
  console.log('🚀 Layout elements added', sceneElements);

  //// Fake Playback UI
  ////
  const playbackElements = await addPlaybackLayoutElements(appDiv);
  console.log('🚀 Layout elements added', playbackElements);

  function addUserInputText(parentElement: Element) {
    const userInputText = document.createElement('spatula-element');
    parentElement.appendChild(userInputText);
  }
  playbackElements.containerForChatBubbles?.addEventListener('mousedown', () => {
    addUserInputText(playbackElements.containerForChatBubbles);
    addUserInputText(playbackElements.containerForChatBubbles);
    addUserInputText(playbackElements.containerForChatBubbles);

    const scrollView = playbackElements.containerForChatBubbles.parentElement!;
    scrollView.scroll({ top: scrollView.scrollHeight, behavior: 'smooth' });
  });

  const playbackCtrl = createPlayerControls(playbackElements);
  Object.assign(globalThis, { playbackCtrl });
  playbackCtrl.onUserInputReceived.on(async (inputKey, inputValue) => {
    const message = `Aknowledged. ${inputKey} is ${inputValue}.`;
    const bubble = playbackCtrl.addChatItem({ message });
    bubble.style.opacity = '0';
    await delay(1.0);
    await bubble.show({ duration: 0.2, scale: 0 });

    const scrollView = playbackElements.containerForChatBubbles.parentElement!;
    scrollView.scroll({ top: scrollView.scrollHeight, behavior: 'smooth' });
  });

  await delay(0.6);

  // const bubble = new ChatBubble();
  // const bubble = document.createElement('chat-bubble');
  // bubble.bubbleContent = 'Hello, World!';
  // bubble.bubbleStyle = 'default-bubble';
  // playbackElements.containerForChatBubbles.append(bubble);

  Object.assign(globalThis, { createIndexedDBService });
  // const _11 = await doTheElevenLabsThing(`say whaaat`);
  // console.log('🎤 Finished 11labs', _11);

  await playbackCtrl.waitUntilUserTap();

  const randomCleverQuotes = [
    'The best way to predict the future is to invent it.',
    'The only way to do great work is to love what you do.',
    'The only limit to our realization of tomorrow will be our doubts of today.',
    'The only thing we have to fear is fear itself.',
    'The only true wisdom is in knowing you know nothing.',
    'The best way to predict the future is to invent it.',
    'The only way to do great work is to love what you do.',
    'The only limit to our realization of tomorrow will be our doubts of today.',
    'The only thing we have to fear is fear itself.',
    'The only true wisdom is in knowing you know nothing.',
  ];
  for (const quote of randomCleverQuotes) {
    const bubble = playbackCtrl.addChatItem({
      message: quote,
      author: Math.random() < 0.5 ? '' : ChatBubblesManager.shifterAuthorValue,
    });
    bubble.style.opacity = '0';
    // await delay(1.0);
    await bubble.show({ duration: 0.2, scale: 0 });
    await delay(0.2);
  }

  playbackCtrl.chatBubblesCtrl.updateBubbleCorners();

  await playbackCtrl.waitUntilUserTap();

  for (let i = 0; i < 10; i++) {
    await playbackCtrl.waitUntilUserAnswer('Foo' + i);
  }

  // addShifterInputTapToContinue(playbackElements.containerForShifterInput);
  // addShifterInputTextField(playbackElements.containerForShifterInput);

  await customAwaitable;
}

async function testErrorMessage() {
  await delay(0.5);
  await displayErrorMessage('This is a development sandbox');
  await delay(0.5);
}

declare global {
  interface Window {
    __sandbox__?: boolean;
  }
}

type GLTF = import('three/examples/jsm/loaders/GLTFLoader.js').GLTF;
function printAnimationNamesObj(obj: GLTF) {
  const result = {} as Record<string, string>;

  for (const animation of obj.animations) {
    const name = animation.name;
    const camelCaseName = toCamelCase(name);
    result[camelCaseName] = name;
  }

  return result;
}
Object.assign(window, { printAnimationNamesObj });

function toCamelCase(str: string): string {
  // Remove all non-alphanumeric and non-space characters
  let alphanumericStr = str.replace(/[^a-zA-Z0-9 ]/g, ' ').replace(/ +/g, ' ');

  // Convert to camel case
  let camelCaseStr = alphanumericStr
    .split(' ')
    .map((word, index) => (index === 0 ? word : word[0] === undefined ? '' : word[0].toUpperCase() + word.slice(1)))
    .join('');

  return camelCaseStr;
}
