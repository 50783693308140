import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
@customElement('founder-section')
export class FounderSection extends LitElement {
  static override styles = css`
    :host {
      display: block;
      color: white;
      font-family: 'Inter', sans-serif;
      box-sizing: border-box;
      background-image: url(/images/About-Background.webp);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    body {
      margin: 0px;
    }

    .align-button {
      padding-top: 760px;
      padding-bottom: 83px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-style {
      padding: 33px 54px;
      border-radius: 120px;
      border: 2px;
      opacity: 0px;
      backdrop-filter: blur(10px);
      background: linear-gradient(6.27deg, rgba(0, 0, 0, 0.1792) 3.12%, rgba(0, 255, 209, 0.1408) 95.05%);
      box-shadow: 0px 6px 8px 0px #ffffff1f inset;
      box-shadow: 0px 2px 12px 0px #43ffbbb8 inset;
    }

    .about-text {
      font-size: 32px;
      font-weight: bold;
      letter-spacing: 2px;
      line-height: 48.73px;
      text-align: center;
      max-width: 939px;
      margin: 0px auto;
    }

    .about-text span {
      font-weight: bolder;
    }

    @media screen and (min-width: 1400px) and (max-width: 1500px) {
      :host {
        background-image: url(/images/About-Background.webp);
      }

      .align-button {
        padding-top: 690px;
        padding-bottom: 50px;
      }

      .button-style {
        padding: 30px 60px;
        border-radius: 90px;
      }

      .about-text {
        font-size: 28px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 40px;
        max-width: 760px;
      }

      .about-text span {
        font-weight: 600;
      }
    }

    @media screen and (min-width: 1300px) and (max-width: 1400px) {
      :host {
        background-image: url(/images/About-Background.webp);
      }

      .align-button {
        padding-top: 700px;
        padding-bottom: 50px;
      }

      .button-style {
        padding: 30px 60px;
        border-radius: 90px;
      }

      .about-text {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 40px;
        max-width: 760px;
      }

      .about-text span {
        font-weight: bolder;
      }
    }

    @media screen and (min-width: 1000px) and (max-width: 1300px) {
      :host {
        background-image: url(/images/About-Background.webp);
      }

      .align-button {
        padding-top: 550px;
        padding-bottom: 20px;
      }

      .button-style {
        padding: 20px 24px;
        border-radius: 75px;
      }

      .about-text {
        font-size: 22px;
        font-weight: bold;
        line-height: 26px;
        max-width: 580px;
      }

      .about-text span {
        font-weight: bolder;
      }
    }

    @media screen and (min-width: 730px) and (max-width: 1000px) {
      :host {
        background-image: url(/images/About-Bg-Small-Screen.avif);
      }

      .align-button {
        padding-top: 350px;
        padding-bottom: 20px;
      }

      .button-style {
        padding: 15px 24px;
        border-radius: 75px;
      }

      .about-text {
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
        max-width: 450px;
        letter-spacing: 0;
      }

      .about-text span {
        font-weight: bolder;
      }
    }

    @media screen and (min-width: 470px) and (max-width: 730px) {
      :host {
        background-image: url(/images/About-Bg-Small-Screen.avif);
      }

      .align-button {
        padding-top: 260px;
        padding-bottom: 10px;
      }

      .button-style {
        padding: 15px 18px 10px;
        border-radius: 45px;
      }

      .about-text {
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        max-width: 390px;
        letter-spacing: 0;
      }

      .about-text span {
        font-weight: bolder;
      }
    }

    @media screen and (min-width: 380px) and (max-width: 470px) {
      :host {
        background-image: url(/images/About-Bg-Small-Screen.avif);
      }

      .align-button {
        padding-top: 143px;
        padding-bottom: 10px;
      }

      .button-style {
        padding: 15px 18px 10px;
        border-radius: 35px;
      }

      .about-text {
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        max-width: 321px;
        letter-spacing: 0;
      }

      .about-text span {
        font-weight: bolder;
      }
    }

    @media (max-width: 380px) {
      :host {
        background-image: url(/images/About-Bg-Small-Screen.avif);
      }

      .align-button {
        padding-top: 143px;
        padding-bottom: 10px;
      }

      .button-style {
        padding: 15px 18px 10px;
        border-radius: 35px;
      }

      .about-text {
        font-size: 11px;
        font-weight: bold;
        line-height: 15px;
        max-width: 280px;
        letter-spacing: 0;
      }

      .about-text span {
        font-weight: bolder;
      }
    }
  `;

  override render() {
    return html`
      <div class="align-button">
        <div class="button-style">
          <p class="about-text">
            <span>The Presence Shift founder is Dr. Sean Sullivan</span>,<br/> a clinical psychologist sharing interactive
            ’mindfulness shifting’ experiences for over 20 years
          </p>
        </div>
      </div>
      <slot></slot>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'founder-section': FounderSection;
  }
}
