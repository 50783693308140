import { LitElement, css, html } from 'lit-element';
import { customElement, query, queryAll } from 'lit/decorators.js';

import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { SkinColors } from '../gui/SkinColors';
import { SkinPickButton } from '../gui/SkinPickButton';

const TAG_NAME = 'shifter-input-pick-skin';
declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: ShifterInputPickSkin;
  }
}

@customElement(TAG_NAME)
export class ShifterInputPickSkin extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .skin-buttons-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    skin-pick-button {
      margin: 0 3vmin;
    }

    button.btn-confirm {
      display: block;
      margin-top: 10vmin;
      pointer-events: all;
      cursor: pointer;

      font-size: 1.25em;
      padding: 0.75rem 1.5rem;
      background-color: rgba(0, 0, 0, 0.4);
      color: white;
      border: none;
      border-radius: 25px;
      cursor: pointer;
      transition:
        color 0.2s,
        opacity 0.2s;

      font-family: Inter;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin-bottom: 3rem;
    }

    button.btn-confirm:hover {
      color: cyan;
    }

    button.btn-confirm[disabled] {
      opacity: 0;
      pointer-events: none;
    }
  `;

  @query('button.btn-confirm')
  private readonly confirmButton!: HTMLButtonElement;

  @queryAll('skin-pick-button')
  private readonly skinButtons!: NodeListOf<SkinPickButton>;

  public readonly onConfirmSelection = createDelegateFunction<[skinSlug: SkinColors]>();
  public readonly onChangeSelection = createDelegateFunction<[skinSlug: SkinColors]>();

  public selectedSkinSlug = null as SkinColors | null;

  render() {
    return html`
      <div class="skin-buttons-container">
        <skin-pick-button color="presence" @click="${this.handleButtonClick}"></skin-pick-button>
        <skin-pick-button color="crimson" @click="${this.handleButtonClick}"></skin-pick-button>
        <skin-pick-button color="emerald" @click="${this.handleButtonClick}"></skin-pick-button>
        <skin-pick-button color="sapphire" @click="${this.handleButtonClick}"></skin-pick-button>
      </div>
      <button class="btn-confirm" @click="${() => this.onConfirmSelection.emit(this.selectedSkinSlug!)}">
        Confirm
      </button>
    `;
  }

  firstUpdated() {
    this.updateState();
  }

  handleButtonClick(e: PointerEvent) {
    const clickedButton = e.currentTarget as SkinPickButton;
    this.selectSkin(clickedButton.color);
  }

  selectSkin(skinSlug: SkinColors) {
    if (this.selectedSkinSlug === skinSlug) {
      this.selectedSkinSlug = null;
    } else {
      this.selectedSkinSlug = skinSlug;
    }

    this.updateState();

    this.onChangeSelection.emit(skinSlug);
  }

  updateState() {
    for (const button of this.skinButtons) {
      button.selected = button.color === this.selectedSkinSlug;
    }

    this.confirmButton.disabled = this.selectedSkinSlug === null;
    this.confirmButton.hidden = this.selectedSkinSlug === null;
  }
}
