import gsap from 'gsap';
import { LitElement, css, html } from 'lit';
import { customElement, query } from 'lit/decorators.js';

import { buildVars } from '@/constants/buildVars';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { trackOrientationAsCSSClass } from '@/util/trackOrientationAsCSSClass';

import '../common/GlassButton';
import './BoomerangVideoBackground';

const TAG_NAME = 'welcome-splash-screen';
const DISMISS_EVENT = 'dismiss';

@customElement(TAG_NAME)
export class WelcomeSplashScreen extends LitElement {
  static styles = css`
    :host {
      --splash-screen-padding: 0vmin;

      position: absolute;
      top: var(--splash-screen-padding);
      left: var(--splash-screen-padding);
      right: var(--splash-screen-padding);
      bottom: var(--splash-screen-padding);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Helvetica Neue', sans-serif;

      zoom: 0.7;
    }

    .container {
      box-sizing: border-box;

      text-align: center;
      color: white;

      position: absolute;
      top: var(--splash-screen-padding);
      left: var(--splash-screen-padding);
      right: var(--splash-screen-padding);
      bottom: var(--splash-screen-padding);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);
    }

    .title {
      font-size: 4.2em;
      text-shadow: 0 2px 1rem #0009;
      margin-bottom: 0.5rem;
      margin-top: 1em;

      font-family: 'Lora', serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;

      transition: font-size 0.2s;
    }

    .subtitle {
      font-size: 1.2em;
      text-shadow: 0 2px 1rem #0009;

      font-family: 'Inter', sans-serif;
      font-optical-sizing: auto;
      font-weight: 300;
      font-style: normal;

      color: #cdcdcd;

      max-width: 720px;
    }

    glass-button {
      margin-top: 5.5rem;
      width: 640px;
      max-width: 80%;
    }

    :host(.portrait) {
      .title {
        font-size: 2.2rem;
      }
      .subtitle {
        font-size: 0.9rem;
      }
    }
  `;

  @query('.start-button')
  public readonly startButton!: HTMLButtonElement;

  @query('.title')
  public readonly titleElement!: HTMLElement;

  @query('.subtitle')
  public readonly subtitleElement!: HTMLElement;

  @query('.container')
  public readonly containerElement!: HTMLElement;

  protected firstUpdated(): void {
    trackOrientationAsCSSClass(this);
  }

  protected renderLittleNote() {
    const note =
      buildVars.mode === 'demo'
        ? 'Use headphones for an optimal experience'
        : 'For an optimal experience use headphones and a Chrome desktop browser';

    return html`
        <div class="subtitle">
          <img src="/images/icons/headphones.svg" alt="Headphones" style="margin-bottom: -3px" />
          ${note}
        </div>
      </div>
    `;
  }

  protected renderLittleNote2() {
    const note =
      buildVars.mode === 'demo'
        ? null
        : 'Presence Shifts currently function best with headphones and a laptop or desktop. We are still perfecting experiences on mobile devices.';

    return html`
        <div class="subtitle">
          <img src="/images/icons/headphones.svg" alt="Headphones" style="margin-bottom: -3px" />
          ${note}
        </div>
      </div>
    `;
  }

  protected render() {
    return html`
      <div class="container">
        <glowy-spinny-dot scale="1.5"></glowy-spinny-dot>

        <div class="title">Welcome to<br />The Presence Shift!</div>

        <glass-button id="begin-button-splash-screen" class="start-button" @click=${this.onClickStart}>
          I'm ready to begin
        </glass-button>
      </div>
    `;
  }

  private onClickStart() {
    this.hideContent();
    this.dispatchEvent(new CustomEvent(DISMISS_EVENT));
  }

  public waitUntilDismissed() {
    const awaitable = createCustomAwaitable();

    const onDismiss = () => {
      awaitable.resolve();
      this.removeEventListener(DISMISS_EVENT, onDismiss);
    };

    this.addEventListener(DISMISS_EVENT, onDismiss);

    return awaitable;
  }

  public async hideContent() {
    if (!this.containerElement) return;
    this.containerElement.style.visibility = 'hidden';
  }

  public async fadeIn(fadeDuration = 1) {
    await gsap.from(this, { duration: fadeDuration, opacity: 0 });
  }

  public async fadeOutAndRemove(fadeDuration = 1) {
    await gsap.to(this, { duration: fadeDuration, opacity: 0 });
    this.remove();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: WelcomeSplashScreen;
  }
}
