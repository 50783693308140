import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { SIGNUP_CLICK_EVENT } from './WebScreen';
import { appContext } from '@/context/appContext';
@customElement('about-section')
export class AboutSection extends LitElement {
  static override styles = css`
    :host {
      display: block;
      color: white;
      font-family: 'Inter', sans-serif;
      box-sizing: border-box;
    }

    body {
      margin: 0px;
    }

    .hero-container {
      max-width: 1290px;
      margin: 0px auto;
      padding: 20px;
      padding-top: 10px;
    }

    .hero-title {
      font-size: 70px;
      font-weight: 500;
      line-height: 82px;
      margin-bottom: 1rem;
      margin-top: 100px;
      font-family: 'Abhaya Libre', serif;
    }

    .border-style {
      background: linear-gradient(to right, #08feb3, #0f80a8);
      width: 100px;
      height: 10px;
      border-radius: 50px;
    }
    .play-pause-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      padding: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
    }
    .video-container {
      position: relative;
      width: 100%;
      max-width: 100%;
      margin: 0px auto;
    }

    .video-adjust {
      width: 100%;
      border-radius: 20px;
      height: 70vh;
    }

    .play-pause-btn img {
      width: 40px;
      height: 40px;
    }

    .play-pause-btn:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
    .hero-text {
      font-size: 22px;
      font-weight: 300;
      line-height: 40px;
      letter-spacing: 0.5px;
      margin-bottom: 100px;
    }

    .text-hero-break {
      display: block;
      margin-bottom: 53px;
    }
    .video-adjust {
      position: relative;
      margin-top: 40px;
      margin-bottom: 100px;
      display: flex;
      justify-content: center;
    }
    .play-icon {
      position: absolute;
      top: 49%;
      cursor: pointer;
    }
    .hero-text-span {
      color: #08feb3;
    }

    .hero-owner {
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      text-decoration: underline;
      margin-bottom: 107px;
      color: white;
      cursor: pointer;
    }

    .center-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .hero-button {
      display: inline-block;
      padding: 16px 153px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: white;
      box-shadow: 0px 6px 8px 0px #ffffff1f inset;
      box-shadow: 0px 2px 12px 0px #43ffbbb8 inset;
      background: linear-gradient(6.27deg, rgba(0, 0, 0, 0.1792) 3.12%, rgba(0, 255, 209, 0.1408) 95.05%);
      border: none;
      border-radius: 50px;
      cursor: pointer;
      text-decoration: none;
    }

    .text-below-button {
      margin-top: 13px;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 80px;
      text-align: center;
    }

    .text-below-button span {
      color: #08feb3;
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      .hero-container {
        padding: 60px;
      }

      .border-style {
        width: 80px;
        height: 7px;
        margin-bottom: 30px;
      }

      .hero-title {
        font-size: 52px;
        line-height: 45px;
        margin-top: 60px;
        margin-bottom: 16px;
      }
      .hero-text {
        margin-bottom: 90px;
      }

      .text-hero-break {
        display: none;
      }

      .hero-owner {
        font-size: 22px;
        line-height: 29px;
        margin-bottom: 70px;
        margin-top: 0px;
      }

      .hero-button {
        padding: 14px 130px;
        font-size: 24px;
        font-weight: 500;
        line-height: 20px;
      }

      .text-below-button {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 0px;
      }
    }

    @media screen and (min-width: 800px) and (max-width: 1200px) {
      .hero-container {
        padding: 60px;
      }
      .border-style {
        width: 80px;
        height: 7px;
        margin-bottom: 30px;
      }

      .hero-title {
        font-size: 52px;
        line-height: 45px;
        margin-bottom: 16px;
        margin-top: 0px;
      }

      .hero-text {
        font-size: 20px;
        line-height: 34px;
        margin-bottom: 70px;
        margin-top: 0px;
      }
      .video-adjust {
        margin-bottom: 70px;
      }

      .text-hero-break {
        display: none;
      }

      .hero-owner {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 80px;
        margin-top: 0px;
      }

      .hero-button {
        padding: 14px 130px;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
      }

      .text-below-button {
        font-size: 20px;
        font-weight: 500;
        line-height: 27px;
        margin-bottom: 30px;
      }
    }

    @media screen and (min-width: 500px) and (max-width: 800px) {
      .hero-container {
        padding: 40px;
      }

      .border-style {
        width: 60px;
        height: 4.5px;
        border-radius: 50px;
        margin-bottom: 20px;
      }

      .hero-title {
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 5px;
        margin-top: 0px;
      }

      .video-adjust {
        margin-bottom: 50px;
        height: auto;
      }

      .hero-text {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 50px;
        margin-top: 0px;
      }

      .text-hero-break {
        display: none;
      }

      .hero-owner {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 50px;
        margin-top: 0px;
      }

      .hero-button {
        padding: 8px 80px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      .text-below-button {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        margin-top: 8px;
        margin-bottom: 10px;
      }
    }

    @media (max-width: 500px) {
      .border-style {
        width: 36.7px;
        height: 4.5px;
        border-radius: 50px;
        margin-bottom: 13px;
      }
      .video-adjust {
        height: auto;
        margin-bottom: 30px;
      }
      .hero-title {
        font-weight: 500;
        font-size: 23px;
        line-height: 27px;
        margin-top: 4px;
        margin-bottom: 5px;
      }

      .hero-text {
        font-size: 12px;
        line-height: 18.73px;
        word-spacing: 0.5px;
        letter-spacing: 0;
        margin-bottom: 36px;
        margin-top: 0px;
      }

      .text-hero-break {
        display: none;
      }

      .hero-owner {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 28px;
        margin-top: 0px;
      }

      .hero-button {
        padding: 4px 54px;
        font-size: 8px;
        font-weight: 500;
        line-height: 20px;
      }

      .text-below-button {
        font-size: 8px;
        font-weight: 400;
        line-height: 13px;
        margin-bottom: 30px;
        margin-top: 5px;
      }
    }
  `;

  override firstUpdated() {
    const video = this.shadowRoot?.getElementById('myVideo') as HTMLVideoElement;
    video.addEventListener('click', () => this.togglePlayPause());
    window.addEventListener('signup', this.onSignupFromHero.bind(this));
  }

  private togglePlayPause() {
    const video = this.shadowRoot?.getElementById('myVideo') as HTMLVideoElement;
    const playPauseBtn = this.shadowRoot?.querySelector('.play-pause-btn') as HTMLElement;
    const { music } = appContext;

    if (video.paused || video.ended) {
      video.play();
      playPauseBtn.style.display = 'none';
      if (music) {
        music.reasonsToMute.set('videoPlaying', true);
      }
    } else {
      video.pause();
      playPauseBtn.style.display = 'flex';
      if (music) {
        music.reasonsToMute.remove('videoPlaying');
      }
    }

    video.onended = () => {
      playPauseBtn.style.display = 'flex';
      if (music) {
        music.reasonsToMute.remove('videoPlaying');
      }
    };
  }

  private onSignupFromHero(event: Event) {
    event.preventDefault();
    const video = this.shadowRoot?.getElementById('myVideo') as HTMLVideoElement;
    if (!video.paused) {
      video.pause();
      const playPauseBtn = this.shadowRoot?.querySelector('.play-pause-btn') as HTMLElement;
      playPauseBtn.style.display = 'flex';
    }

    const { music } = appContext;
    if (music) {
      music.reasonsToMute.remove('videoPlaying');
    }
  }

  override render() {
    return html`
      <div class="hero-container">
        <!-- Your existing content -->

        <!-- Video Section -->
        <div class="video-container">
          <div class="play-pause-btn" @click="${this.togglePlayPause}">
            <img src="/images/video-play-icon.svg" alt="Play Icon" />
          </div>
          <div>
            <video id="myVideo" class="video-adjust" poster="/images/poster.webp">
              <source src="/video/SnapSave.mp4" type="video/mp4" />
            </video>

          </div>
        </div>

        <div class="center-content">
          <a href="#" class="hero-button" @click=${this.onSignupClick}>Sign up</a>
          <p class="text-below-button">
            Signing up prepares your<br />
            <span>7-Day Starter Program</span>
          </p>
        </div>
      </div>
      <slot></slot>
    `;
  }

  private onSignupClick(event: Event) {
    event.preventDefault();

    const video = this.shadowRoot?.getElementById('myVideo') as HTMLVideoElement;
    if (!video.paused) {
      video.pause();
      const playPauseBtn = this.shadowRoot?.querySelector('.play-pause-btn') as HTMLElement;
      playPauseBtn.style.display = 'flex';
    }

    const { music } = appContext;
    if (music) {
      music.reasonsToMute.remove('videoPlaying');
    }

    this.dispatchEvent(new CustomEvent(SIGNUP_CLICK_EVENT, { bubbles: true, composed: true }));
  }
}
