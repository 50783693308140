import { signInWithEmail, signUpWithEmail } from '@/backend/auth-with-email';
import { signInWithGoogle } from '@/backend/auth-with-platforms';
import { appContext } from '@/context/appContext';
import { defaultVars } from '@/misc/defaultVars';
import { ConversionReporting } from '@/tools/gtag/gtagReportConversion';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { FirebaseError } from 'firebase/app';
import { SignInForm } from './components/SignInForm';
import { SignUpForm } from './components/SignUpForm'; // Assuming a similar component for SignUp
import { displayLearnMoreSplash_SignUpVersion } from './displayLearnMoreSplash';
import { displayErrorMessage } from './system/displayErrorMessage';
import { createElementFadeInOutControl } from './utils/createElementFadeInOutControl';

export async function displaySigningSplash(parentElement: HTMLElement) {
  const awaitable = createCustomAwaitable<void>();

  async function resolveAndFinish(action: PromiseLike<unknown>) {
    try {
      await action;
      awaitable.resolve();
    } catch (error) {
      console.error(error);

      const details = error instanceof FirebaseError ? error.code : String(error);
      await displayErrorMessage(details, 'Signing in failed');

      splashManager.currentForm!.loading = false;
    }
  }

  // Factory object for creating or reusing form instances
  const formFactory = {
    signInForm: null as SignInForm | null,
    signUpForm: null as SignUpForm | null,

    getSignInForm() {
      if (!this.signInForm) {
        this.signInForm = document.createElement(SignInForm.tagName);

        this.signInForm.onSignUpWithPlatformClick.on(async platform => {
          ConversionReporting.reportConversion('sign_in');

          if (platform === 'google') {
            await resolveAndFinish(signInWithGoogle());
          } else {
            throw new Error(`Unsupported platform: ${platform}`);
          }

          appContext.events.userConversionCheckpoint.emit('signed_in');
        });

        this.signInForm.onSignInClick.on(async data => {
          ConversionReporting.reportConversion('sign_in');

          console.log('📝 Sign in form submitted:', data);
          await resolveAndFinish(signInWithEmail(data.email, data.password));

          appContext.events.userConversionCheckpoint.emit('signed_in');
        });

        this.signInForm.onSignUpClick.on(async () => {
          const signUpForm = formFactory.getSignUpForm();
          await splashManager.switchTo(signUpForm);
        });
      }
      return this.signInForm;
    },

    getSignUpForm() {
      if (!this.signUpForm) {
        this.signUpForm = document.createElement(SignUpForm.tagName);

        this.signUpForm.onSignUpWithPlatformClick.on(async platform => {
          ConversionReporting.reportConversion('sign_in');

          if (platform === 'google') {
            await resolveAndFinish(signInWithGoogle());
          } else {
            throw new Error(`Unsupported platform: ${platform}`);
          }

          appContext.events.userConversionCheckpoint.emit('signed_up');
        });
        this.signUpForm.onSubmit.on(async data => {
          ConversionReporting.reportConversion('sign_up');

          console.log('📝 Sign up form submitted:', data);

          async function signUp() {
            if (data.email.length === 0) {
              throw new Error('Email is required');
            }

            if (data.password.length === 0) {
              throw new Error('Password is required');
            }

            if (data.password !== data.passwordConfirmation) {
              throw new Error('Passwords do not match');
            }

            await signUpWithEmail(data.email, data.password);
          }

          await resolveAndFinish(signUp());

          appContext.events.userConversionCheckpoint.emit('signed_up');
        });

        this.signUpForm.onLearnMore.on(async () => {
          this.signUpForm!.style.visibility = 'hidden';
          const storedShifterName = await appContext.userData.profile.getFieldValue('name');
          const shifterName = storedShifterName ?? defaultVars.shifter_name;
          await displayLearnMoreSplash_SignUpVersion(parentElement, shifterName);
          this.signUpForm!.style.visibility = 'visible';
        });

        this.signUpForm.onSignInClick.on(async () => {
          const signInForm = this.getSignInForm();
          await splashManager.switchTo(signInForm);
        });
      }
      return this.signUpForm;
    },
  };

  // Manager object for handling the display of forms
  type FormSplash = SignInForm | SignUpForm;
  const splashManager = {
    currentForm: null as FormSplash | null,

    async switchTo(newForm: FormSplash | null) {
      if (this.currentForm) {
        const faderOut = createElementFadeInOutControl(this.currentForm);
        faderOut.fadeOutAndRemove(0.6);
      }

      if (newForm) {
        parentElement.appendChild(newForm);
        this.currentForm = newForm;

        await newForm.updateComplete;
        newForm.setBusyState(false);

        const faderIn = createElementFadeInOutControl(newForm);
        await faderIn.fadeIn(0.3);
      }
    },
  };

  // {
  //   const signInForm = formFactory.getSignInForm();
  //   await splashManager.switchTo(signInForm);
  // }

  {
    const signUpForm = formFactory.getSignUpForm();
    await splashManager.switchTo(signUpForm);
  }

  await awaitable;

  await splashManager.switchTo(null);
}
