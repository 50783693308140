import { ensureUserAuthenticated } from '@/backend/ensureUserSignedIn';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../backend/firebase';
import { nextFrame } from '@/util/nextFrame';

export function createFirestoreUserDataService<T extends Record<string, any>>(
  docName: string,
  createEmptyUserData: () => T
) {
  const auth = getAuth();
  let userId = auth.currentUser?.uid;
  if (!userId) {
    ensureUserAuthenticated().then(() => {
      userId = auth.currentUser?.uid;
    });
  }
  onAuthStateChanged(auth, u => (userId = u?.uid));

  const getUserData = async () => {
    if (!userId) {
      userId = await ensureUserAuthenticated();
    }

    const docRef = doc(db, docName, userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() } as T & { id: string };
    } else {
      console.warn(`${userId} not found in ${docName}`);
      return createEmptyUserData();
    }
  };

  const updateUserData = async (newData: Partial<T>) => {
    if (!userId) {
      userId = await ensureUserAuthenticated();
    }

    const docRef = doc(db, docName, userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, newData as T);
    } else {
      await setDoc(docRef, newData as T);
    }
  };

  let cache: T & { id?: string } = createEmptyUserData();
  const applyInitialDataToCache = async () => {
    await nextFrame();
    await ensureUserAuthenticated();
    const data = await getUserData();
    Object.assign(cache, data);
  };
  const createDataProxy = () => {
    applyInitialDataToCache();

    return new Proxy(cache, {
      get: (target, prop) => target[prop as keyof typeof target],
      set: (target, prop, value) => {
        target[prop as keyof typeof target] = value;
        updateUserData({ [prop]: value } as T);
        return true;
      },
    });
  };
  const dataProxy = createDataProxy();

  return {
    getUserId: () => userId,
    getUserData,
    getFieldValue: async <K extends keyof T>(fieldName: K) => {
      const data = await getUserData();
      return data[fieldName] as T[K];
    },
    setFieldValue: async <K extends keyof T>(fieldName: K, value: T[K]) => {
      cache[fieldName] = value;
      await updateUserData({ [fieldName]: value } as T);
    },
    updateUserData,
    proxy: dataProxy,
  };
}

export type FirestoreUserDataController = ReturnType<typeof createFirestoreUserDataService>;
