import * as THREE from 'three';

export function createVideoTextureFromUrl(url: string, mods: Partial<HTMLVideoElement> = {}): THREE.VideoTexture {
  // Create a video element
  const video = document.createElement('video');
  video.src = url;
  video.autoplay = true;
  video.loop = true;
  video.muted = true;
  video.playsInline = true;

  requestAnimationFrame(() => {
    video.play();
  });

  // Apply mods
  Object.assign(video, mods);

  // Create a texture from the video
  const texture = new THREE.VideoTexture(video);
  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;
  // texture.colorSpace = THREE.SRGBColorSpace;

  return texture;
}
