import { auth } from '@/backend/firebase';
import { onAuthStateChanged } from 'firebase/auth';

import { deleteDoc, doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '@/backend/firebase';

export function enableCopyingDataOnAuthStateChanged() {
  let currentAuthState = null as null | UserAuthState;

  onAuthStateChanged(auth, user => {
    const previousAuthState = currentAuthState;
    currentAuthState = !user ? null : { uid: user.uid, isAnonymous: user.isAnonymous };

    //// Without previous user state, we don't have any data to copy over
    if (!previousAuthState) return;

    //// Without a current user state, we don't have anywhere to copy the data over to
    if (!currentAuthState) return;

    //// If the user IDs are the same, we don't have to copy anything
    if (previousAuthState?.uid === currentAuthState?.uid) return;

    //// If the user wasn't anonymous, we don't have to copy anything
    if (!previousAuthState?.isAnonymous) return;

    //// If the user is anonymous, we mustn't copy anything
    if (currentAuthState?.isAnonymous) return;

    //// Got it. Copy the user's data to the new uid in the database
    return copyUserData(previousAuthState.uid, currentAuthState.uid);
  });
}

async function copyUserData(fromUid: string, toUid: string) {
  try {
    const collections = ['UserProfiles', 'UserShifts', 'UserPurchases', 'UserAnswers'];

    for (const collectionName of collections) {
      const fromDocRef = doc(db, collectionName, fromUid);
      const fromDocSnap = await getDoc(fromDocRef);

      if (fromDocSnap.exists()) {
        const data = fromDocSnap.data();

        console.log('📝 Copying data from:', fromDocRef.path, 'to:', collectionName, toUid);

        // Special handling for UserPurchases
        if (collectionName === 'UserPurchases') {
          const truthyPurchases = Object.entries(data)
            .filter(([_, value]) => Boolean(value))
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

          await setDoc(doc(db, collectionName, toUid), truthyPurchases, {
            merge: true,
          });

          console.log("- 📝 Won't delete:", fromDocRef.path);
        } else {
          await setDoc(doc(db, collectionName, toUid), data, { merge: true });

          // Delete the document for the anonymous user
          await deleteDoc(fromDocRef);
        }
      }
    }

    // Save the mapping of anonymous UID to real user UID
    await setDoc(doc(db, 'AnonymousUserConversions', fromUid), {
      realUserUid: toUid,
      convertedAt: serverTimestamp(),
    });

    console.log('User data copied and cleaned up successfully');
  } catch (error) {
    console.error('Error copying user data:', error);
  }
}

type UserAuthState = {
  uid: string;
  isAnonymous: boolean;
};
