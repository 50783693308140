import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

interface CarouselItem {
  imgSrc: string;
  description: string;
  reviewBy?: string[];
}

const TAG_NAME = 'testimonials-section';

@customElement(TAG_NAME)
export class TestimonialsSection extends LitElement {
  static readonly tagName = TAG_NAME;
  @property({ type: Array })
  items: CarouselItem[] = [];

  @property({ type: Number })
  activeIndex = 0;

  private autoplayInterval: number | undefined;
  private mouseOver = false;

  private startX = 0;
  private isDragging = false;

  static override styles = css`
    :host {
      display: block;
      color: white;
      font-family: 'Inter', sans-serif;
      box-sizing: border-box;
    }

    body {
      margin: 0px;
    }

    .testimonials-container {
      max-width: 1290px;
      margin: 0px auto;
      padding: 20px;
    }
    .main-flex {
      margin-top: 140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      overflow: hidden;
    }
    .text-bold {
      font-weight: 700;
    }
    .dot-container img {
      margin-top: 7px;
    }
    .carousel-section-wrapper {
      overflow: hidden;
      max-width: 738px;
    }
    .carousel-wrapper {
      display: flex;
      gap: 24px;
      transition: transform 0.5s ease-in-out;
      width: 100%;
    }
    .dot-container-small-screen {
      display: none;
    }
    .carousel-item {
      flex: 1 0 50%;
      max-width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
    }
    .active-card-border {
      border: 1px solid #00ffc2;
      border-radius: 50px;
      width: 25px;
      height: 25px;
      margin-top: 90px;
    }
    .active-card-dot {
      margin: 8px;
      width: 9px;
      height: 9px;
      background-color: #00ffc2;
      border-radius: 50px;
    }

    .testimonials-title {
      font-size: 60px;
      font-weight: 500;
      line-height: 70px;
      margin-bottom: 24px;
      margin-top: 0px;
      font-family: 'Abhaya Libre', serif;
    }
    .testimonials-border-style {
      background: linear-gradient(to right, #08feb3, #0f80a8);
      width: 100px;
      height: 10px;
      border-radius: 50px;
      margin-bottom: 30px;
    }
    .testimonials-text {
      font-size: 17px;
      font-weight: 400;
      line-height: 26px;
      margin-top: 0px;
    }
    .testimonials-text-span {
      font-weight: 700;
      color: #08feb3;
    }
    .box-layout {
      padding: 50px 27px 20px 27px;
      border-radius: 20px;
      border: 2px;
      max-width: 357px;
      opacity: 0px;
      background: linear-gradient(6.27deg, rgba(0, 0, 0, 0.1792) 3.12%, rgba(0, 255, 209, 0.1408) 95.05%);
      box-shadow: 0px 6px 8px 0px #ffffff1f inset;
      box-shadow: 0px 2px 12px 0px #43ffbbb8 inset;
    }
    .star-adjustment {
      margin-bottom: 24px;
    }
    .carousel-description-text {
      font-size: 22px;
      line-height: 28px;
      max-width: 316px;
      font-weight: 300;
      margin-bottom: 21px;
      margin-top: 0px;
    }
    .reviews-by {
      font-size: 16px;
      line-height: 22px;
      max-width: 316px;
      font-weight: 300;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .dot-container {
      display: flex;
      gap: 18px;
      margin-top: 98px;
      align-items: center;
    }
    .dot {
      width: 10px;
      height: 10px;
      background-color: #ccc;
      border-radius: 50%;
      cursor: pointer;
    }
    .dot.active {
      background-color: #00ffc2;
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      .main-flex {
        margin-top: 60px;
      }
      .testimonials-container {
        padding: 60px;
      }
      .testimonials-title {
        font-size: 52px;
        line-height: 45px;
        margin-bottom: 16px;
      }
      .dot-container img {
        margin-top: 7px;
      }
      .testimonials-border-style {
        width: 80px;
        height: 7px;
        margin-bottom: 30px;
      }
    }

    @media screen and (min-width: 800px) and (max-width: 1200px) {
      .testimonials-title {
        font-size: 50px;
        line-height: 45px;
        text-align: center;
        margin-bottom: 16px;
      }
      .testimonials-text {
        margin-bottom: 30px;
      }
      .testimonials-border-style {
        width: 80px;
        height: 7px;
        margin-bottom: 30px;
      }
      .main-flex {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .testimonials-border-adjust {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .active-card-border {
        display: none;
      }
      .dot-container {
        display: none;
      }
      .dot-container-small-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: 50px;
      }
      .dot-container-small-screen img {
        margin-top: 7px;
      }
      .justify-dots {
        display: flex;
        justify-content: center;
      }
      .active-card-border-screen {
        border: 1px solid #00ffc2;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        margin-top: 53px;
        display: block;
      }
    }

    @media screen and (min-width: 650px) and (max-width: 800px) {
      .testimonials-container {
        padding: 40px;
      }
      .carousel-section-wrapper {
        max-width: 700px;
      }
      .testimonials-title {
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 15px;
        text-align: center;
      }
      .testimonials-text {
        font-weight: 400;
        margin-bottom: 30px;
      }
      .testimonials-border-style {
        width: 60px;
        height: 4.5px;
        border-radius: 50px;
        margin-bottom: 20px;
      }
      .main-flex {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .carousel-description-text {
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        max-width: 316px;
        margin-bottom: 18px;
        margin-top: 0px;
      }
      .reviews-by {
        font-size: 13px;
        line-height: 16px;
        max-width: 316px;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      .testimonials-border-adjust {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .dot-container-small-screen img {
        margin-top: 7px;
      }
      .active-card-border {
        display: none;
      }
      .justify-dots {
        display: flex;
        justify-content: center;
      }
      .dot-container {
        display: none;
      }
      .dot-container-small-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: 30px;
      }
      .active-card-border-screen {
        border: 1px solid #00ffc2;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        margin-top: 53px;
        display: block;
      }
      .box-layout {
        padding: 40px 20px;
      }
      .star-adjustment {
        margin-bottom: 20px;
      }
    }
    @media (max-width: 650px) {
      .carousel-section-wrapper {
        max-width: 430px;
      }
    }
    @media (max-width: 650px) {
      .carousel-item {
        flex: 1 0 100%;
        max-width: 100%;
      }
      .main-flex {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .testimonials-text-span {
        font-weight: 400;
      }
      .dot-container-small-screen img {
        max-width: 16px;
        max-height: 16px;
        margin-top: 7px;
      }
      .dot {
        width: 6px;
        height: 6px;
      }
      .testimonials-title {
        font-size: 28px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 5px;
        text-align: center;
      }
      .testimonials-border-style {
        width: 50px;
        height: 3.5px;
        border-radius: 50px;
        margin-bottom: 20px;
      }
      .testimonials-text {
        margin-bottom: 25px;
        font-weight: 300;
        font-size: 13px;
        line-height: 20px;
      }
      .testimonials-border-adjust {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .carousel-description-text {
        font-size: 15px;
        font-weight: 300;
        line-height: 24px;
        max-width: 316px;
        margin-bottom: 15px;
        margin-top: 0px;
      }
      .reviews-by {
        font-size: 13px;
        line-height: 22px;
        max-width: 316px;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      .box-layout {
        padding: 45px 23px;
        height: 248px;
      }
      .star-adjustment {
        margin-bottom: 14px;
      }
      .dot-container {
        display: none;
      }
      .dot-container-small-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: 20px;
      }
      .carousel-wrapper {
        display: flex;
        align-items: center;
        transition: transform 0.5s ease-in-out;
      }
      .text-break {
        display: none;
      }
      .carousel-section-wrapper {
        max-width: 357px;
        margin: 0 auto;
      }
    }
    @media (max-width: 400px) {
      .carousel-section-wrapper {
        max-width: 330px;
        margin: 0 auto;
      }
      .carousel-wrapper {
        gap: 50px;
      }
      .box-layout {
        height: auto;
      }
    }
    @media (max-width: 350px) {
      .carousel-section-wrapper {
        max-width: 250px;
        margin: 0 auto;
      }
      .carousel-wrapper {
        gap: 130px;
      }
      .box-layout {
        height: auto;
      }
    }
  `;

  constructor() {
    super();
    this.activeIndex = 0;
  }

  override firstUpdated() {
    if (typeof this.items === 'string') {
      try {
        this.items = JSON.parse(this.items as unknown as string);
      } catch (e) {
        console.error('Failed to parse items:', e);
      }
    }

    this.startAutoplay();
  }

  startAutoplay() {
    this.autoplayInterval = window.setInterval(() => {
      if (!this.mouseOver) {
        this.activeIndex = (this.activeIndex + 1) % this.items.length;
      }
    }, 6000);
  }

  stopAutoplay() {
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval);
    }
  }

  override connectedCallback() {
    super.connectedCallback();
    this.addEventListener('mouseover', this.handleMouseOver);
    this.addEventListener('mouseout', this.handleMouseOut);

    // Mouse events
    this.addEventListener('mousedown', this.handleMouseDown);
    this.addEventListener('mousemove', this.handleMouseMove);
    this.addEventListener('mouseup', this.handleMouseUp);
    this.addEventListener('mouseleave', this.handleMouseLeave);

    // Touch events
    this.addEventListener('touchstart', this.handleTouchStart);
    this.addEventListener('touchmove', this.handleTouchMove);
    this.addEventListener('touchend', this.handleTouchEnd);
  }

  override disconnectedCallback() {
    this.stopAutoplay();
    this.removeEventListener('mouseover', this.handleMouseOver);
    this.removeEventListener('mouseout', this.handleMouseOut);

    // Remove mouse events
    this.removeEventListener('mousedown', this.handleMouseDown);
    this.removeEventListener('mousemove', this.handleMouseMove);
    this.removeEventListener('mouseup', this.handleMouseUp);
    this.removeEventListener('mouseleave', this.handleMouseLeave);

    // Remove touch events
    this.removeEventListener('touchstart', this.handleTouchStart);
    this.removeEventListener('touchmove', this.handleTouchMove);
    this.removeEventListener('touchend', this.handleTouchEnd);

    super.disconnectedCallback();
  }

  handleMouseOver() {
    this.mouseOver = true;
  }

  handleMouseOut() {
    this.mouseOver = false;
  }

  handleMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.pageX;
  }

  handleMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      const currentX = event.pageX;
      const diffX = currentX - this.startX;
      if (Math.abs(diffX) > 50) {
        this.isDragging = false;
        this.handleSlide(diffX);
      }
    }
  }

  handleMouseUp() {
    this.isDragging = false;
  }

  handleMouseLeave() {
    this.isDragging = false;
  }

  handleTouchStart(event: TouchEvent) {
    this.isDragging = true;
    this.startX = event.touches[0].clientX;
  }

  handleTouchMove(event: TouchEvent) {
    if (this.isDragging) {
      const currentX = event.touches[0].clientX;
      const diffX = currentX - this.startX;
      if (Math.abs(diffX) > 50) {
        this.isDragging = false;
        this.handleSlide(diffX);
      }
    }
  }

  handleTouchEnd() {
    this.isDragging = false;
  }

  handleSlide(diffX: number) {
    if (diffX > 0) {
      this.activeIndex = (this.activeIndex - 1 + this.items.length) % this.items.length;
    } else {
      this.activeIndex = (this.activeIndex + 1) % this.items.length;
    }
  }

  override render() {
    const screenWidth = window.innerWidth;
    let cardWidth, gapWidth;

    if (screenWidth <= 470) {
      cardWidth = 380;
      gapWidth = 0;
    } else if (screenWidth <= 650) {
      cardWidth = 380;
      gapWidth = 0;
    } else if (screenWidth <= 900) {
      cardWidth = 300;
      gapWidth = 0;
    } else {
      cardWidth = 357;
      gapWidth = 24;
    }

    const itemsToRender = [...this.items, ...this.items];

    return html`
      <div class="testimonials-container">
        <div class="main-flex">
          <div>
            <h1 class="testimonials-title">Testimonials</h1>
            <div class="testimonials-border-adjust">
              <div class="testimonials-border-style"></div>
            </div>
            <p class="testimonials-text">
              <span class="testimonials-text-span">Dr. Sean Sullivan</span>
              <span class="text-bold">Is A Practicing Clinical Psychologist</span><br class="text-break" />
              Sharing Mind-Body Shifting Experiences For Over
              <br class="text-break" />
              Twenty Years.
            </p>
            <div class="dot-container">
              ${this.items.map(
                (_, index) => html`
                  <div @click="${() => (this.activeIndex = index)}">
                    ${this.activeIndex === index
                      ? html`<img src="/images/active-dot.svg" alt="Active Dot Icon" />`
                      : html`<div class="dot"></div>`}
                  </div>
                `
              )}
            </div>
          </div>
          <div class="carousel-section-wrapper">
            <div
              class="carousel-wrapper"
              style="transform: translateX(-${this.activeIndex * cardWidth + this.activeIndex * gapWidth}px);"
            >
              ${itemsToRender.map(
                item => html`
                  <div class="carousel-item box-layout">
                    <img src="${item.imgSrc}" alt="Stars" class="star-adjustment" />
                    <p class="carousel-description-text">"${item.description}"</p>
                    <p class="reviews-by">${item.reviewBy}</p>
                  </div>
                `
              )}
            </div>
            <div class="dot-container-small-screen">
              ${this.items.map(
                (_, index) => html`
                  <div @click="${() => (this.activeIndex = index)}">
                    ${this.activeIndex === index
                      ? html`<img src="/images/active-dot.svg" alt="Active Dot Icon" />`
                      : html`<div class="dot"></div>`}
                  </div>
                `
              )}
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'testimonials-section': TestimonialsSection;
  }
}
