import { LitElement, css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

import { createCustomAwaitable } from '@/util/core/createCustomAwaitable.js';

// Importing your components
import '../common/GlassButton.js';
import './AboutSection.js';
import './DisclaimerSection.js';
import './FeedbackSection.js';
import './FounderScreen.js';
import './HeroSection.js';
import './TestimonialsSection.js';

const TAG_NAME = 'web-screen';

export const SIGNUP_CLICK_EVENT = 'signup';

@customElement(TAG_NAME)
export class WebScreen extends LitElement {
  // Define any properties here, if needed
  @property({ type: Number }) someProperty = 0;

  @query('hero-section')
  private heroSection!: HTMLElement;

  // Define component-specific styles using `css`
  static styles = css`
    :host {
      display: block;
      background-size: cover;
      position: relative;
      height: 100%;
      width: 100%;
      overflow-y: scroll;
    }
    body {
      margin: 0 !important;
    }

    .background-middle-screen {
      background-image: url('/images/Middle-Section-Background.webp');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  `;

  // Render method to define the template
  render() {
    return html`
      <hero-section></hero-section>
      <founder-section></founder-section>
      <div class="background-middle-screen">
        <testimonials-section
          items='[
    {
      "imgSrc": "/images/stars.svg",
      "description": "90% of our surveyed Shift app users shifted toward or into a better state of mind in < 10 minutes.",
      "reviewBy": "Pilot Supported By The Regents Of University Of California"
    },
    {
      "imgSrc": "/images/stars.svg",
      "description": "There is a massive need for this product.",
      "reviewBy": "Paul Marcille, Ph.D., Psychologist and former Professor and Program Director, Palo Alto University and President of California Psychological Association"
    },
    {
      "imgSrc": "/images/stars.svg",
      "description": "Dr. Sullivan digital tools are like having a psychologist who is your close friend with you every day. Its so helpful and it works.",
      "reviewBy": "Marta Wegorzewska, Ph.D., Scientific Editor. Washington University, St. Louis, MO"
    },
    {
      "imgSrc": "/images/stars.svg",
      "description": "Our dream is to play a meaningful role transforming mobile devices into pathways to health and well-being.",
      "reviewBy": "Sean Sullivan, Psy.D., The Presence Shift founder"
    }
  ]'
        ></testimonials-section>
        <feedback-section></feedback-section>
        <about-section></about-section>
        <disclaimer-section></disclaimer-section>
      </div>
    `;
  }

  public waitUntilSignUpClicked() {
    const awaitable = createCustomAwaitable();

    const onSignup = () => {
      awaitable.resolve();
      this.removeEventListener(SIGNUP_CLICK_EVENT, onSignup);
    };

    this.addEventListener(SIGNUP_CLICK_EVENT, onSignup);

    return awaitable;
  }
}

// Declare the global interface for your custom element
declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: WebScreen;
  }
}
