import {
  GoogleAuthProvider,
  User,
  browserLocalPersistence,
  onAuthStateChanged,
  setPersistence,
  signInAnonymously,
} from 'firebase/auth';
import { auth } from './firebase';

let signInPromise = null as Promise<string> | null;

async function signInAnonymouslyMethod() {
  console.log(`(func) 🔑 Signing in anonymously...`);

  // Set the persistence level
  await setPersistence(auth, browserLocalPersistence);

  const result = await signInAnonymously(auth);
  const user = result.user;

  return user;
}

async function _ensureUserSignedIn() {
  const signedInAlready = await checkUserSignedIn();
  console.log(`🔑 Signed in already: ${signedInAlready}`);

  const currentUser: User | null = auth.currentUser;

  if (currentUser) {
    return currentUser.uid;
  }

  try {
    await signInAnonymouslyMethod();
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.customData.email;
    const credential_1 = GoogleAuthProvider.credentialFromError(error);
    console.error(`🔑`, { errorCode, errorMessage, email, credential_1 });
  }

  if (!auth.currentUser) {
    throw new Error('User sign in failed');
  }

  const uid = auth.currentUser.uid;
  console.log(`🔑`, uid, { user: auth.currentUser });

  return uid;
}

export async function ensureUserAuthenticated() {
  if (signInPromise) {
    return signInPromise;
  }

  signInPromise = _ensureUserSignedIn();
  return signInPromise;
}

export function checkUserSignedIn(): Promise<boolean> {
  return new Promise(resolve => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe();
      if (user) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
}

// Function to check if user is logged in
export function checkUserSignedInNotAnonymously() {
  return new Promise<boolean>(resolve => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe();

      const loggedIn = user !== null && !user.isAnonymous;

      console.log(`
        🔑 
        Signed in: ${user !== null}
        Signed in properly: ${loggedIn}
        user: ${user?.uid}
        providers: ${user?.providerData.map(p => p.providerId)}
        displayName: ${user?.displayName}
        phoneNumber: ${user?.phoneNumber}
        email: ${user?.email}
        isAnonymous: ${user?.isAnonymous}`);

      resolve(loggedIn);
    });
  });
}
