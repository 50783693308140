import { appContext } from '@/context/appContext';
import gsap from 'gsap';

import { LitElement, css, html } from 'lit';
import { customElement, query } from 'lit/decorators.js';

@customElement('intro-video')
export class IntroVideo extends LitElement {
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 7;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;

  @query('video')
  public readonly videoElement!: HTMLVideoElement;

  render() {
    return html`<video autoplay muted playsinline></video>`;
  }

  protected firstUpdated(): void {
    appContext.animCtrl.hookUpToTimeScale(value => (this.videoElement.playbackRate = value));
  }

  playMedia(sourceUrls: string[]) {
    const { videoElement } = this;

    if (!videoElement) {
      throw new Error('No video element found');
    }

    while (videoElement.firstChild) {
      videoElement.removeChild(videoElement.firstChild);
    }

    // Add new source elements
    sourceUrls.forEach(sourceUrl => {
      const source = document.createElement('source');
      source.src = sourceUrl;
      videoElement.appendChild(source);
    });

    const canPlayAwaitable = new Promise<void>(resolve => {
      const onCanPlay = () => {
        videoElement.removeEventListener('canplay', onCanPlay);
        resolve();
      };

      videoElement.addEventListener('canplay', onCanPlay);
    });

    // Load the new sources and play the video
    videoElement.muted = true;
    videoElement.load();

    const playAwaitable = videoElement.play();

    const firstTimeUpdateAwaitable = new Promise<void>(resolve => {
      const onTimeUpdate = () => {
        resolve();
        videoElement.removeEventListener('timeupdate', onTimeUpdate);
      };

      videoElement.addEventListener('timeupdate', onTimeUpdate);
    });

    const sevenSecondsMarkAwaitable = new Promise(resolve => {
      const sevenSeconds = 7;

      const onDone = () => {
        videoElement.removeEventListener('ended', onDone);
        videoElement.removeEventListener('timeupdate', checkVideoTime);
        resolve(null);
      };

      const checkVideoTime = () => {
        if (videoElement.currentTime < sevenSeconds) return;
        onDone();
      };

      videoElement.addEventListener('timeupdate', checkVideoTime);
      videoElement.addEventListener('ended', onDone);
    });

    return {
      canPlayAwaitable,
      playAwaitable,
      firstTimeUpdateAwaitable,
      sevenSecondsMarkAwaitable,
    };
  }

  async fadeIn(fadeDuration: number) {
    await gsap.from(this.videoElement, { opacity: 0, duration: fadeDuration });
  }

  async fadeOutAndRemove(fadeDuration: number) {
    await gsap.to(this, { opacity: 0.001, duration: fadeDuration });
    this.remove();
  }
}
