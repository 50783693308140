import { signInWithPhoneNumber, RecaptchaVerifier, linkWithPhoneNumber, ConfirmationResult } from 'firebase/auth';
import { auth } from './firebase';
import { FirebaseError } from 'firebase/app';

// Initialize reCAPTCHA verifier
function initRecaptchaVerifier() {
  //// get parent for recaptcha (#recaptcha-container) or create one if not found in body
  let recaptchaContainer = document.querySelector<HTMLElement>('#recaptcha-container');
  if (!recaptchaContainer) {
    recaptchaContainer = document.createElement('div');
    recaptchaContainer.id = 'recaptcha-container';
    document.body.appendChild(recaptchaContainer);
  }

  window.recaptchaVerifier = new RecaptchaVerifier(auth, recaptchaContainer!, {
    size: 'invisible',
  });
}

function removeRecaptchaContainer() {
  const recaptchaContainer = document.querySelector<HTMLElement>('#recaptcha-container');
  recaptchaContainer?.remove();
}

// Sign in with phone number
export async function signInWithPhone(phoneNumber: string) {
  try {
    initRecaptchaVerifier();
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
    return confirmationResult;
  } catch (error) {
    console.error('🔑 Error signing in with phone:', error);
    return null;
  } finally {
    removeRecaptchaContainer();
  }
}

// Verify the code sent to the user's phone
export async function checkPhoneVerificationCode(confirmationResult: ConfirmationResult, code: string) {
  try {
    const result = await confirmationResult.confirm(code);
    console.log('User signed in with phone number:', result.user);
  } catch (error) {
    if (error instanceof FirebaseError) {
      const existsTypeErrorCode = 'auth/account-exists-with-different-credential';
      if (error.code === existsTypeErrorCode) {
        console.warn('🔑 Phone number already linked to another account.');
        return null;
      }

      const alreadyLinkedErrorCode = 'auth/provider-alreadt-linked';
      if (error.code === alreadyLinkedErrorCode) {
        console.warn('🔑 A phone number is already linked to this account.');
        return null;
      }
    }

    throw error;
  }
}

// Link phone number to an already signed-in user
export async function linkPhoneNumberToUser(phoneNumber: string) {
  if (!auth.currentUser) {
    console.error('No user is signed in to link a phone number.');
    return;
  }

  try {
    initRecaptchaVerifier();
    const confirmationResult = await linkWithPhoneNumber(auth.currentUser, phoneNumber, window.recaptchaVerifier);
    return confirmationResult;
  } catch (error) {
    throw error;
  } finally {
    removeRecaptchaContainer();
  }
}

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
  }
}
