import Showdown from 'showdown';

import gsap from 'gsap';

import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { createSocialSharingService, SocialSharePlatform } from '@/services/createSocialSharingService';
import { createStripePaymentService } from '@/services/createStripePaymentService';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { buildVars } from '@/constants/buildVars';
import { ConversionReporting } from '@/tools/gtag/gtagReportConversion';

export async function displayLearnMoreSplash_SignUpVersion(
  parentElement: HTMLElement,
  shifterName: string | undefined
) {
  const lmComponent = new LearnMoreSplash();

  lmComponent.variant = 'v3';
  lmComponent.showSignUpCall = false; // true // Turning this off for now
  lmComponent.returnLinkLabel = 'Return to Sign Up';

  if (shifterName !== undefined) {
    lmComponent.shifterName = shifterName;
  }

  parentElement.append(lmComponent);

  const awaitable = createCustomAwaitable();
  lmComponent.onReturnLinkClick.on(() => {
    awaitable.resolve();
  });

  await lmComponent.fadeIn();
  await awaitable;
  await lmComponent.fadeOutAndRemove();
}

export async function displayLearnMoreSplash_ShareVersion(parentElement: HTMLElement, shifterName: string | undefined) {
  const lmComponent = new LearnMoreSplash();

  lmComponent.variant = 'v2';
  lmComponent.returnLinkLabel = 'Return to Presence Shift 1';

  if (shifterName !== undefined) {
    lmComponent.shifterName = shifterName;
  }

  parentElement.append(lmComponent);

  const awaitable = createCustomAwaitable();
  lmComponent.onReturnLinkClick.on(() => {
    awaitable.resolve();
  });

  await lmComponent.fadeIn();
  await awaitable;
  await lmComponent.fadeOutAndRemove();
}

export async function displayLearnMoreSplash_EndVersion(parentElement: HTMLDivElement, shifterName: string) {
  const lmComponent = new LearnMoreSplash();
  lmComponent.variant = 'v1';
  lmComponent.shifterName = shifterName;
  lmComponent.showSubForBeta = Boolean(buildVars.showSubscribeForBetaPaymentLink);
  lmComponent.showSocialSharingButtons = Boolean(buildVars.showShareButtonsOnLearnMore);
  lmComponent.showSubstackLink = Boolean(buildVars.showSubstackLink);
  lmComponent.pps = `
  Keep an eye on your email for the next steps of your Starter Program. 

  We’ll be in touch soon :)

  P.P.S. Share The Presence Shift with someone you care about…
  `;
  parentElement.append(lmComponent);

  const shareService = createSocialSharingService();
  lmComponent.onShare.on(async platform => {
    ConversionReporting.reportConversion(`share_on_${platform}`);
    await shareService.shareOnPlatform(platform, shifterName);
  });

  await lmComponent.fadeIn();
}

type LearnMoreSplashVariant = 'v1' | 'v2' | 'v3';

@customElement('learn-more-splash')
export class LearnMoreSplash extends LitElement {
  @property({ type: String }) shifterName = 'friend';
  @property({ type: String }) founderName = 'Dr. Sean Sullivan';
  @property({ type: String }) founderDescription =
    'a practicing clinical psychologist sharing mind and body shifting experiences with patients and subscribers for over twenty years';

  @property({ type: String }) pps = '';

  @property({ type: String }) variant = 'v1' as LearnMoreSplashVariant;
  @property({ type: String }) returnLinkLabel = null as string | null;
  @property({ type: Boolean }) showSubForBeta = false;
  @property({ type: Boolean }) showSocialSharingButtons = false;
  @property({ type: Boolean }) showSubstackLink = false;
  @property({ type: Boolean }) showSignUpCall = false;

  @property({ type: Boolean }) busy = false;

  public readonly onShare = createDelegateFunction<[platform: SocialSharePlatform]>();
  public readonly onReturnLinkClick = createDelegateFunction();

  static styles = css`
    :host {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 10vmin;
      color: #fff;
      font-family: Arial, sans-serif;
      line-height: 1.6;

      overflow-y: auto;
      background-color: #0d1b2a9f;
      backdrop-filter: blur(50px);
    }

    .content {
      max-width: 120vmin;
    }

    .section-one {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: end;
      justify-content: space-between;
      align-content: space-between;
      justify-items: stretch;
      gap: 20px;
    }

    .section-one > * {
      grow: 1;
    }

    .return-link {
      color: #0075ff;
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 39px; /* 97.5% */
      text-decoration-line: underline;
      text-shadow: 0 0 4px #0000ff00;
      transition:
        color 0.2s,
        text-shadow 0.2s;
    }

    .return-link:hover {
      color: #30a5ff;
      text-shadow: 0 0 4px #1085ff;
      text-decoration-line: underline;
    }

    .title {
      color: var(--White, #fff);
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 39px; /* 121.875% */
    }

    .right-side {
      margin-bottom: 1.5em;
    }

    .section-two {
      color: #ececec;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
    }

    .smaller {
      font-size: 20px;
    }

    .link-sub {
      color: #0ff;
      text-decoration: underline;
      cursor: pointer;
      text-shadow:
        0 0 0px #0ff0,
        0 0 0px #0ff0;
      transition-duration: 0.2s;
      transition-property: color, text-shadow;
      transition-timing-function: ease-in-out;
    }
    .link-sub:hover {
      color: #fff;
      text-shadow:
        0 0 24px #00f,
        0 0 12px #0ff,
        0 0 4px #0ff;
    }

    .share-buttons {
      display: flex;
      gap: 15px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @media (max-width: 600px) {
      .title {
        font-size: 20px;
        line-height: 100%;
      }
      .section-one {
        font-size: 15px;
        grid-template-columns: 1fr;
        flex-direction: column-reverse;
      }
      .section-two {
        font-size: 15px;
      }
    }
  `;

  private renderReturnLinkMaybe(className: string) {
    if (!this.returnLinkLabel) return null;

    if (this.busy) return null;

    return html`
      <a href="##" class="${className}" @click=${this.onReturnLinkClick} id="return-link"> ${this.returnLinkLabel} </a>
    `;
  }

  private renderSubcribeForBetaMaybe() {
    if (!this.showSubForBeta) return null;

    return html`
      <p>
        P.S.
        <span id="subscribe-for-beta-link" class="link-sub" @click=${this.handleClickSubscribe}>Subscribe to beta</span>
        to get Presence Shift 2 on launch day. ${!this.busy ? null : html`<small-spinner size="1em"></small-spinner>`}
      </p>
    `;
  }

  renderSubstackLinkMaybe() {
    if (!this.showSubstackLink) return null;

    // const url = 'https://www.oneperfectshift.com';
    const url = buildVars.substackLinkUrl;
    return html`
      <p>
        P.S.
        <a id="substack-link" class="link-sub" href="${url}" target="_blank"> Subscribe to stay informed </a>
      </p>
    `;
  }

  renderSignUpCall() {
    if (!this.showSignUpCall) return null;

    return html`
      <p>
        P.S. Signing up today will also unlock your
        <b>Shift Starter Program</b> — our introductory program for learning how to strategically shift your state of
        mind and body on demand.
      </p>
    `;
  }

  renderShareButtonsMaybe() {
    if (!this.showSocialSharingButtons) return null;

    const shareService = createSocialSharingService();
    const sharePlatforms = shareService.getSupportedPlatforms();

    return html`
      <div class="share-buttons">
        ${sharePlatforms.map(platform => {
          return html`
            <social-icon
              id="share-button-${platform}-learn-more"
              icon=${platform}
              @click="${() => this.onShare(platform)}"
            ></social-icon>
          `;
        })}
      </div>
    `;
  }

  render() {
    const greeting =
      this.variant === 'v2' ? html`<p>I hope you’re enjoying Presence Shift 1 as much as we are.</p>` : null;

    const mdConverter = new Showdown.Converter();
    const innerTextMd = TEXT[this.variant];
    const innerText = mdConverter.makeHtml(innerTextMd);
    const innerTextHtml = unsafeHTML(innerText);

    const endingParagraph =
      this.variant === 'v3'
        ? html` <p>You're always welcome here. We look forward to shifting with you for many years to come.</p> `
        : html`
            <p>
              You're always welcome here, ${this.shifterName}. We look forward to shifting with you and Presence for
              many years to come.
            </p>
          `;

    const pps = unsafeHTML(mdConverter.makeHtml(this.pps));

    return html`
      <div class="content">
        <div class="section-one">
          <div class="left-side">
            <tps-logo></tps-logo>
            <custom-space></custom-space>
            <custom-space></custom-space>
            ${this.renderReturnLinkMaybe('return-link')}
            <custom-space></custom-space>
            <custom-space></custom-space>
            <custom-space></custom-space>
            <custom-space></custom-space>
            <custom-space></custom-space>
            <div class="title">Welcome to The Presence Shift, ${this.shifterName}</div>
            ${greeting}
          </div>
          <div class="right-side">
            <sean-and-presence></sean-and-presence>
          </div>
        </div>
        <div class="section-two">
          ${innerTextHtml}

          <div class="footer">
            ${endingParagraph}
            <p><b>Dr. Sean Sullivan & The Presence Shift team</b></p>
          </div>

          ${this.renderSubcribeForBetaMaybe()} ${this.renderSubstackLinkMaybe()} ${this.renderSignUpCall()} ${pps}

          <custom-space></custom-space>

          ${this.renderReturnLinkMaybe('return-link smaller')} ${this.renderShareButtonsMaybe()}
        </div>
      </div>
    `;
  }

  async handleClickSubscribe() {
    try {
      ConversionReporting.reportConversion(`pay_beta`);

      this.busy = true;
      const paymentService = createStripePaymentService('accessBeta');
      await paymentService.performStripePayment();
      const success = await paymentService.checkIfUserPaidSuccessfully();
      this.busy = false;
      if (success) {
        alert("🎉 Thanks for subscribing! We'll let you know when we launch PS2.");
      }
    } catch (error) {
      console.error('🔑 Error subscribing:', error);
    } finally {
      this.busy = false;
    }
  }

  async fadeIn() {
    await gsap.from(this, {
      scale: 1.15,
      opacity: 0,
      duration: 0.5,
      ease: 'power2.inOut',
    });
  }

  async fadeOutAndRemove() {
    await gsap.to(this, {
      scale: 1.1,
      opacity: 0,
      duration: 0.3,
      ease: 'power2.in',
    });
    this.remove();
  }
}

@customElement('tps-logo')
class TPSLogo extends LitElement {
  static styles = css`
    :host {
      display: block;
      width: 100px;
      height: auto;
    }
  `;

  render() {
    return html` <img src="/images/about/tps-logo.png" alt="TPS Logo" /> `;
  }
}

@customElement('sean-and-presence')
class SeanAndPresence extends LitElement {
  static styles = css`
    :host {
      display: block;
      width: fit-content;
    }

    img {
      width: 100%;
      max-width: 40vmin;
      height: auto;
      border-radius: 1em;
      box-shadow: 0 0.5em 1em #0005;
    }

    small {
      display: block;
      text-align: center;
      font-size: 0.7em;
      font-weight: bold;
      line-height: 1.5em;
      opacity: 0.8;
      width: 100%;
    }

    @media (max-width: 600px) {
      :host {
        margin-top: 2em;
        margin-bottom: 2em;
      }

      img {
        width: 100%;
        max-width: 100vmin;
      }
    }
  `;

  render() {
    return html`
      <img src="/images/about/sean-and-presence.png" alt="Sean and Presence" />
      <small
        >The Presence Shift founder and voice is Dr. Sean Sullivan, a practicing clinical psychologist sharing mind and
        body shifting experiences with patients and subscribers for over twenty years.
      </small>
    `;
  }
}

@customElement('custom-space')
class Space extends LitElement {
  static styles = css`
    :host {
      height: 1em;
      display: block;
      width: 100%;
    }
  `;

  render() {
    return html``;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    space: Space;
    'sean-and-presence': SeanAndPresence;
    'tps-logo': TPSLogo;
    'learn-more-splash': LearnMoreSplash;
  }
}

const TEXT = {
  v1: `
You are now part of a growing community grounded in treating everyone with the
unconditional respect, honesty, humor and recognition that we all wish for our own
family members. We honor this community vision by paying Presence Shifts forward to
friend(s) as an act of kindness that can lead to a chain reaction of positive actions.
And, we strategically shift our states of mind and body together with Presence Shift
adventures informed by ancient wisdom, empirical science and life experience.

Presence Shift 1 introduces a core psychology theme: Our thoughts determine how we feel.
Presence then navigates us into a fully present, connected state of mind and body called
The Infinite Perfection. This first Presence Shift draws inspiration from ancient
Dzogchen, a Tibetan word meaning ‘Great Perfection’ — a state of deep inner peace and
freedom native to all humans. Presence-centered wisdom traditions such as Dzogchen teach
that we each possess the innate ability to embody powerful inner states of peace,
freedom and love.

Meanwhile, empirical science confirms that consistently making strategic, mindful shifts
to how you’re feeling strengthens your mental and physical health. By focusing 100% of
your attention right here and right now, you can drop into presence, deepen your
connection to others and re-sync with the miraculous adventure that life is. That’s why
we believe in putting beautifully personalized (and fun) Presence Shifts at your
fingertips, and navigating through our incredible states of mind, body and soul
together.
  `,
  v2: `
The Presence Shift is a growing community grounded in treating everyone with the
unconditional respect, honesty, humor and recognition that we all wish for our own
family members. We honor this vision by paying Presence Shifts forward to friend(s) as
an act of kindness that can lead to a chain reaction of positive actions. And, we
strategically shift our states of mind and body together with Presence Shift
adventures informed by ancient wisdom, empirical science and life experience.

Presence Shift 1 introduces a core psychology theme: Our thoughts determine how we feel.
Presence then navigates us into a fully present, connected state of mind and body called
The Infinite Perfection. This first Presence Shift draws inspiration from ancient
Dzogchen, a Tibetan word meaning ‘Great Perfection’ — a state of deep inner peace and
freedom native to all humans. Presence-centered wisdom traditions such as Dzogchen teach
that we each possess the innate ability to embody powerful inner states of peace,
freedom and love.

Meanwhile, empirical science confirms that consistently making strategic, mindful shifts
to how you’re feeling strengthens your mental and physical health. By focusing 100% of
your attention right here and right now, you can drop into presence, deepen your
connection to others and re-sync with the miraculous adventure that life is. That’s why
we believe in putting beautifully personalized (and fun) Presence Shifts at your
fingertips, and navigating through our incredible states of mind, body and soul
together.
  `,
  v3: `
You’ve come to the right place if you want to strategically shift your state of mind and body anytime.  Presence Shifts are beautiful conversational experiences that guide you into your selected state of mind and body on demand. When you sign up, you’ll unlock Presence Shift 1 and become part of a growing community grounded in ‘shifting’ together while treating everyone with unconditional respect, honesty, humor and recognition.

Presence Shift 1 introduces a core psychology theme: Our thoughts determine how we feel.  Your guide ‘Presence’ then navigates you into a fully present, connected state of mind and body called The Infinite Perfection. This first Presence Shift draws inspiration from ancient Dzogchen, a Tibetan word meaning ‘Great Perfection’ — a state of deep inner peace and freedom native to all humans.  Presence-centered wisdom traditions such as Dzogchen teach that we each possess the innate ability to embody powerful inner states of peace, freedom and love.

Consistently making strategic, mindful shifts to how you’re feeling also strengthens your mental and physical health. By focusing 100% of your attention right here and right now, you can drop into presence, deepen your connection to others and re-sync with the miraculous adventure that life is.  That’s why we believe in putting beautifully personalized (and fun) Presence Shifts at your fingertips and navigating through our incredible states of mind, body and soul together.
  `,
};
