import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

@customElement('feedback-section')
export class FeedbackSection extends LitElement {
  @property({ type: Boolean }) showAll = false;
  @state() currentItemIndex = 0;
  @property({ type: Array }) feedbackItems = [
    {
      text: 'I think the state of mind I felt after using the app/meditation was really nice. The feeling of a cleared mind was something I looked forward to after every session.',
      isOpen: false,
    },
    {
      text: 'I was able to choose different meditations based on my feelings and mood.',
      isOpen: false,
    },
    {
      text: 'I was able to look forward to meditation and really shut myself out from the outside world for just a little bit.',
      isOpen: false,
    },
    {
      text: '…addressed my issues of overthinking and allowed myself to check in daily with my mental state, especially during these times. I was stressed at first because I was unsure of whether I was meditating correctly or using the app, but I feel once I was able to connect with myself and the app, the anxiety lessened and I felt I could enjoy the app/meditation more',
      isOpen: false,
    },
    {
      text: 'I appreciate the ability to disconnect from everything going on and connecting with myself for 5-10 minutes.',
      isOpen: false,
    },
    {
      text: 'Helped me get into meditation. Not something I do regularly but having short 5-8 minute guided meditations made the jump to meditating not too overwhelming.',
      isOpen: false,
    },
    {
      text: '…helped me address internal struggles I am currently dealing with and allowed me to accept and not judge myself for feelings/thoughts I have.',
      isOpen: false,
    },
    {
      text: 'It has helped me by guiding me in finding out more within myself, allowing myself to evaluate any problems I may have rather than ignoring them.',
      isOpen: false,
    },
    {
      text: 'It helped me with my anxiety.',
      isOpen: false,
    },
    {
      text: 'I like the fact that he’s a psychologist. That’s pretty cool.',
      isOpen: false,
    },
  ];

  static override styles = css`
    :host {
      display: block;
      color: white;
      font-family: 'Inter', sans-serif;
      box-sizing: border-box;
    }

    body {
      margin: 0;
    }

    .feedback-container {
      max-width: 1290px;
      margin: 0 auto;
      padding: 20px;
    }

    .feedback-title {
      font-size: 60px;
      font-weight: 500;
      line-height: 68px;
      text-align: center;
      margin-top: 120px;
      margin-bottom: 30px;
      font-family: 'Abhaya Libre', serif;
    }

    .border-style {
      background: linear-gradient(to right, #08feb3, #0f80a8);
      width: 100px;
      height: 10px;
      border-radius: 50px;
    }
    .feedback-border-adjust {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropdown-layout {
      margin-bottom: 20px;
      padding: 29px 35px;
      border-radius: 120px;
      max-height: 84px;
      border: 2px;
      opacity: 0.9;
      background: linear-gradient(6.27deg, rgba(0, 0, 0, 0.1792) 3.12%, rgba(0, 255, 209, 0.1408) 95.05%);
      box-shadow:
        0px 6px 8px 0px #ffffff1f inset,
        0px 2px 12px 0px #43ffbbb8 inset;
    }
    .feedback-dropdown {
      margin-top: 70px;
    }
    .justify-button {
      display: flex;
      justify-content: end;
    }
    .dropdown-layout {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .dropdown-layout.open {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-radius: 20px;
      max-height: 300px;
      line-height: 30px;
    }
    .feedback-text {
      font-size: 22px;
      font-weight: 400;
      margin: 0px;
      letter-spacing: normal;
      max-width: 1120px;
    }
    .dropdown-button {
      width: 31px;
      height: 31px;
      border-radius: 50px;
      border: 2px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .dropdown-button.open {
      border-color: #08feb3;
      color: #08feb3;
    }

    .dropdown-icon {
      font-size: 24px;
      margin: 0;
      text-align: center;
    }
    .show-more-button {
      font-size: 15px;
      font-weight: 400;
      color: #00ffc2;
      cursor: pointer;
      padding-top: 25px;
      padding-left: 65px;
    }
    .flex-arrows {
      display: none;
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      .feedback-container {
        padding: 60px;
      }
      .feedback-title {
        margin-top: 40px;
        font-size: 52px;
        line-height: 60px;
        margin-bottom: 30px;
      }
      .border-style {
        width: 80px;
        height: 7px;
        margin-bottom: 0px;
      }
    }

    @media screen and (min-width: 800px) and (max-width: 1200px) {
      .feedback-container {
        padding: 60px;
      }
      .feedback-title {
        margin-top: 40px;
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 16px;
      }
      .border-style {
        width: 80px;
        height: 7px;
        margin-bottom: 0px;
      }
      .feedback-text {
        font-size: 20px;
        margin-top: 0;
      }
      .dropdown-icon {
        font-size: 20px;
      }
      .dropdown-button {
        width: 25px;
        height: 25px;
      }
    }

    @media screen and (min-width: 500px) and (max-width: 800px) {
      .feedback-container {
        padding: 40px;
      }
      .feedback-title {
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 15px;
        margin-top: 20px;
      }
      .border-style {
        width: 60px;
        height: 4.5px;
        border-radius: 50px;
        margin-bottom: 20px;
      }
      .feedback-text {
        font-size: 18px;
        margin-top: 0;
      }
      .feedback-dropdown {
        margin-top: 40px;
      }
    }

    @media (max-width: 500px) {
      .feedback-container {
        padding: 36px;
      }
      .icon-size {
        width: 21px;
        height: 21px;
      }
      .feedback-title {
        font-size: 23px;
        line-height: 27px;
        font-weight: 500;
        margin-bottom: 10px;
        margin-top: 0px;
      }
      .border-style {
        width: 36.7px;
        height: 4.5px;
        border-radius: 50px;
        margin-bottom: 0px;
      }
      .feedback-text {
        font-size: 11px;
        max-width: 278px;
        margin-top: 0;
      }
      .flex-arrows {
        padding-top: 0px;
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;
      }
      .dropdown-layout.open {
        line-height: 20px;
      }
      .arrow-button-back {
        width: fit-content;
        padding: 0px 15.64px 8px 15.64px;
        border: 1px solid #00ffc2;
        border-radius: 50px;
      }
      .arrow-button-next {
        width: fit-content;
        padding: 0px 15.64px 8px 15.64px;
        background-color: #00ffc2;
        border-radius: 50px;
      }
      .break-tag {
        display: none;
      }
      .dropdown-icon {
        font-size: 20px;
      }
      .dropdown-button {
        border: 1px solid white;
        width: 20px;
        height: 20px;
      }
      .dropdown-layout {
        padding: 20px;
      }
      .show-more-button {
        display: none;
      }
      .feedback-dropdown {
        margin-top: 30px;
      }
    }
  `;

  private toggleDropdown(index: number) {
    const isSmallScreen = window.innerWidth <= 500;
    const actualIndex = isSmallScreen ? this.currentItemIndex : index;

    this.feedbackItems = this.feedbackItems.map((item, i) => ({
      ...item,
      isOpen: i === actualIndex ? !item.isOpen : item.isOpen,
    }));
  }

  private toggleShowAll() {
    this.showAll = !this.showAll;
  }

  private showNextItem() {
    this.currentItemIndex = (this.currentItemIndex + 1) % this.feedbackItems.length;
  }

  private showPreviousItem() {
    this.currentItemIndex = (this.currentItemIndex - 1 + this.feedbackItems.length) % this.feedbackItems.length;
  }

  override render() {
    const isSmallScreen = window.innerWidth <= 500;
    const itemsToShow = isSmallScreen
      ? [this.feedbackItems[this.currentItemIndex]]
      : this.showAll
        ? this.feedbackItems
        : this.feedbackItems.slice(0, 4);

    return html`
      <div class="feedback-container">
        <h1 class="feedback-title">
          Our Existing<br class="break-tag" />
          Subscriber’s Feedback
        </h1>
        <div class="feedback-border-adjust">
          <div class="border-style"></div>
        </div>

        <div class="feedback-dropdown">
          ${itemsToShow.map(
            (item, index) => html`
              <div class="dropdown-layout ${item.isOpen ? 'open' : ''}">
                <p class="feedback-text">"${item.isOpen ? item.text : `${item.text.slice(0, 106)}`}"</p>
                <div class="justify-button">
                  <div class=" ${item.isOpen ? 'open' : ''}" @click="${() => this.toggleDropdown(index)}">
                    <img
                      class="icon-size"
                      src="${item.isOpen ? '/images/minus.svg' : '/images/plus.svg'}"
                      alt="${item.isOpen ? 'Minus Icon' : 'Plus Icon'}"
                    />
                  </div>
                </div>
              </div>
            `
          )}
        </div>

        <p class="show-more-button" @click="${this.toggleShowAll}">${this.showAll ? 'Show Less' : 'Show More'}</p>

        <div class="flex-arrows">
          <div class="" @click="${this.showPreviousItem}">
            <img src="/images/arrow-back.svg" alt="Arrow" />
          </div>
          <div class="" @click="${this.showNextItem}">
            <img src="/images/arrow-next.svg" alt="Arrow" />
          </div>
        </div>
      </div>
      <slot></slot>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'feedback-section': FeedbackSection;
  }
}
