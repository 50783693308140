import gsap from 'gsap';
import { LitElement, css, html } from 'lit';
import { customElement, query } from 'lit/decorators.js';

import { buildVars } from '@/constants/buildVars';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { trackOrientationAsCSSClass } from '@/util/trackOrientationAsCSSClass';

import '../common/GlassButton';

const TAG_NAME = 'introduction-splash-screen';
const DISMISS_EVENT = 'dismiss';

@customElement(TAG_NAME)
export class IntroductionSplashScreen extends LitElement {
  static styles = css`
    :host {
      --splash-screen-padding: 0vmin;

      position: absolute;
      top: var(--splash-screen-padding);
      left: var(--splash-screen-padding);
      right: var(--splash-screen-padding);
      bottom: var(--splash-screen-padding);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Helvetica Neue', sans-serif;
      background: linear-gradient(to bottom, #001a33, #006666);
      color: white;

      transition: zoom 0.5s ease-in-out;
    }

    .container {
      box-sizing: border-box;
      text-align: left;
      max-width: 600px;
      padding: 2rem;
      display: flex;
      flex-direction: column;
    }

    .founder-info {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
    }

    .founder-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 1rem;
    }

    .founder-details {
      display: flex;
      flex-direction: column;
    }

    .founder-name {
      font-size: 1rem;
      font-weight: bold;
    }

    .founder-title {
      font-size: 0.8rem;
      opacity: 0.8;
    }

    .founder-description {
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }

    .title {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    .description {
      font-size: 20px;
      line-height: 1.6;
    }

    .highlight {
      color: #00ffcc;
      font-weight: bold;
    }

    glass-button {
      margin-top: 1rem;
      width: 100%;
    }

    .additional-info {
      margin-top: 0.5rem;
      font-size: 0.8rem;
      opacity: 0.8;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }

    .testimonials {
      margin-top: 1.5rem;
      font-size: 0.9rem;
      cursor: pointer;
      text-align: center;
    }

    p {
      margin: 0.5rem;
    }

    :host(.portrait) {
      zoom: 0.8;

      .title {
        font-size: 2rem;
      }
      .description {
        font-size: 20px;
      }
    }
  `;

  @query('.sign-up-button')
  public readonly signUpButton!: HTMLButtonElement;

  @query('.container')
  public readonly containerElement!: HTMLElement;

  protected firstUpdated(): void {
    trackOrientationAsCSSClass(this);
  }

  protected render() {
    return html`
      <div class="container">
        <sean-and-presence-big></sean-and-presence-big>

        <h1 class="title">Welcome!</h1>

        <p class="description">
          Settle in for a incredible shift to your state of mind and body. In
          <span class="highlight">Presence Shift 1</span>, we'll dive into a beautiful conversational world of peace,
          love, and humor — and shift your state of being to your desire. <br />
        </p>

        <p><b>Dr. Sean Sullivan</b></p>

        <glass-button id="sign-up-button" class="sign-up-button" @click=${this.onClickSignUp}> Sign up </glass-button>

        <p class="additional-info">
          Signing up will also unlock your<br />
          <span class="highlight">7-Day Shift Starter Program</span>
        </p>
      </div>
    `;
  }

  private onClickSignUp() {
    this.dispatchEvent(new CustomEvent(DISMISS_EVENT));
  }

  private onClickTestimonials() {
    // Implement testimonials display logic here
    console.log('Show testimonials');
  }

  public waitUntilDismissed() {
    const awaitable = createCustomAwaitable();

    const onDismiss = () => {
      awaitable.resolve();
      this.removeEventListener(DISMISS_EVENT, onDismiss);
    };

    this.addEventListener(DISMISS_EVENT, onDismiss);

    return awaitable;
  }

  public async fadeIn(fadeDuration = 1) {
    await gsap.from(this, { duration: fadeDuration, opacity: 0 });
  }

  public async fadeOutAndRemove(fadeDuration = 1) {
    await gsap.to(this, { duration: fadeDuration, opacity: 0 });
    this.remove();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: IntroductionSplashScreen;
  }
}

@customElement('sean-and-presence-big')
class SeanAndPresence extends LitElement {
  static styles = css`
    :host {
      display: block;
      width: fit-content;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 1em;
      box-shadow: 0 0.5em 1em #0005;
    }

    small {
      display: block;
      text-align: center;
      font-size: 0.7em;
      font-weight: bold;
      line-height: 1.5em;
      opacity: 0.8;
      width: 100%;
    }

    @media (max-width: 600px) {
      :host {
        margin-top: 2em;
        margin-bottom: 2em;
      }

      img {
        width: 100%;
      }
    }
  `;

  render() {
    return html`
      <img src="/images/about/sean-and-presence.png" alt="Sean and Presence" />
      <small
        >The Presence Shift founder and voice is Dr. Sean Sullivan, a practicing clinical psychologist sharing mind and
        body shifting experiences with patients and subscribers for over twenty years.
      </small>
    `;
  }
}

export async function displayIntroductionSplash(parentElement: HTMLElement) {
  const introductionSplash = new IntroductionSplashScreen();
  parentElement.appendChild(introductionSplash);
  await introductionSplash.fadeIn(0.5);
  await introductionSplash.waitUntilDismissed();
  await introductionSplash.fadeOutAndRemove(0.5);
}
