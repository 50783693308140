import { buildVars } from '@/constants/buildVars';
import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: buildVars.openaiApiKey,
  dangerouslyAllowBrowser: true,
});

export const EMOTION_SENTIMENTS = ['positive', 'negative', 'neutral'];
export const EMOTION_CATEGORIES = [
  'calmness',
  'joy',
  'anger',
  'enthusiasm',
  'sadness',
  'warmth',
  'curiosity',
  'shame',
  'fear',
  'apathy',
  'fragility',
  'guilt',
  'pride',
  'anxiety',
  'confusion',
  'jibberish',
];

export async function processUserEmotionViaAI(userInput: string, recognizedEmotions: readonly string[]) {
  const response = await openai.chat.completions.create({
    // model: 'gpt-3.5-turbo-0125',
    model: 'gpt-4o',
    messages: [
      {
        role: 'user',
        content: `
        I surveyed 100 people and asked them how they feel right now (one word).

        Help me classify the the responses like so:
        - Please pick the closest emotion from the list, matching the person's response.
        - Please also pick the closest emotion category the person's response fits into.
        - Please also pick the correct emotion sentiment (positive, negative, neutral).

        Categorize as "jibberish" if impossible, but avoid it as much as possible.
        Try your best to interpret what the user meant to say. Pay attention to typos, slang, variations, pronounciation, etc.

        Okay. Ready? Here's the first response: "${userInput}"
        `,
      },
    ],
    tools: [
      {
        type: 'function',
        function: {
          name: 'classify_emotion',
          description: 'Classifies the given response into the closest matching emotion from a list.',
          parameters: {
            type: 'object',
            properties: {
              emotion: {
                type: 'string',
                description: 'The emotion, e.g. "happiness", "sadness", "anger", etc.',
                enum: recognizedEmotions,
              },
              category: {
                type: 'string',
                description: 'The emotion sentiment, e.g. "positive".',
                enum: EMOTION_CATEGORIES,
              },
              sentiment: {
                type: 'string',
                description: 'The emotion sentiment, e.g. "positive".',
                enum: EMOTION_SENTIMENTS,
              },
            },
            required: ['emotion', 'category', 'sentiment'],
          },
        },
      },
    ],
    tool_choice: 'auto', // auto is default, but we'll be explicit
  });

  try {
    const responseMessage = response.choices[0].message;
    console.log({ responseMessage });

    const argsJson = responseMessage.tool_calls?.[0]?.function?.arguments!;
    const args = JSON.parse(argsJson) as {
      emotion: string;
      category: string;
      sentiment: 'positive' | 'neutral' | 'negative';
    };

    if (!recognizedEmotions.includes(args.emotion)) {
      console.warn(
        `🎤 AI failed to classify the user's emotion correctly. It responded with "${args.emotion}" which is not in the list of recognized emotions.`
      );
    }

    // _fooo += `
    // User: ${userInput}
    // AI - Emotion: ${args.emotion}
    // AI - Category: ${args.category}
    // AI - Sentiment: ${args.sentiment}
    // `;

    return args;
  } catch (error) {
    return null;
  }
}

Object.assign(window, {
  processUserEmotion: async (input: string) => {
    const emotion = await processUserEmotionViaAI(input, ['happy', 'sad', 'angry', 'neutral']);
    console.log(
      `%cAI classified the user's emotion as: %c${emotion}`,
      'font-weight: bold;',
      'color: yellow; font-weight: bold;'
    );
  },
  // fooo() {
  //   return _fooo;
  // },
});

// let _fooo = '';
