import gsap from 'gsap';

import { createHomeSceneControls } from '../animation/createHomeSceneControls';
import { addLoadingSplash } from '../html/addLoadingSplash';
import { addSceneLayoutElements } from '../html/addSceneLayoutElements';
import { createThreeScene } from '../scene/createThreeScene';
import { ensureAllAssetsLoaded } from '../scene/ensureAllAssetsLoaded';
import { setupAndAddModelsToScene } from '../scene/setupAndAddModelsToScene';
import { createDelegateFunction } from '../util/core/createDelegateFunction';
import { trackPromise } from '../util/core/trackPromise';
import { appContext } from './appContext';

export async function createSceneContext() {
  const { appDiv } = appContext;

  const assetsLoadingPromise = trackPromise(ensureAllAssetsLoaded());

  const elements = await addSceneLayoutElements(appDiv);
  console.log('🚀 Layout elements added', elements);

  const sceneCtrl = createThreeScene({
    enableBloom: appContext.orientationTracker.getIsLandscape(),
  });
  console.log('🎞 Scene created:', sceneCtrl.scene);

  const shouldSkipEntrance = appContext.urlParams.skipVideo || appContext.urlParams.ff || appContext.urlParams.ffTo;

  const onEverythingLoaded = createDelegateFunction();
  const isEverythingLoaded = () => assetsLoadingPromise.isResolved();

  if (!isEverythingLoaded()) {
    const removeLoadingSplash = addLoadingSplash(appDiv);
    onEverythingLoaded.once(removeLoadingSplash);
  }

  const gltfs = await assetsLoadingPromise;

  ///// make copies/clones of the gltfs so that we can mutate them
  // const gltfs = _gltfs.map(gltf => gltf.clone());

  console.log('📦 Models loaded:', gltfs);

  onEverythingLoaded();

  setupAndAddModelsToScene(gltfs, sceneCtrl.scene);
  gltfs.home.scene.visible = true;

  elements.setSceneContainerContent(sceneCtrl.renderer.domElement);

  const onEnterFrameCallbacks = [] as (() => void)[];
  const addEnterFrameCallback = (cb: () => void) => onEnterFrameCallbacks.push(cb);
  sceneCtrl.scene.onBeforeRender = () => {
    if (!sceneCtrl.getRenderingEnabled()) return;
    onEnterFrameCallbacks.forEach(cb => cb());
  };

  const homeCtrl = createHomeSceneControls(gltfs.home, addEnterFrameCallback);
  homeCtrl.clipCtrl.playAnimationState('Cycle', 0, 0.4);

  sceneCtrl.startFrameLoop(elements.containerForTheScene);

  const context = {
    sceneCtrl,
    homeCtrl,
    gltfs,
    elements,
    addEnterFrameCallback,
    async animateIn() {
      const fadeInAwaitable = gsap.from(sceneCtrl.renderer.domElement, {
        opacity: 0,
        duration: shouldSkipEntrance ? 0.5 : 1.5,
      });
      const cameraTweenAwaitable = sceneCtrl.tweenCameraFrom([0, 550, 4800], shouldSkipEntrance ? 0.5 : 3);
      const fakeLightsAwaitable = homeCtrl.fadeFakeLightsIntensityTo(0.25, 5, 1);
      await Promise.all([fadeInAwaitable, cameraTweenAwaitable, fakeLightsAwaitable]);
    },
    async animateOut() {
      const tweenOutDuration = 1.6;
      await Promise.all([
        gsap.to(sceneCtrl.renderer.domElement, {
          opacity: 0,
          duration: tweenOutDuration,
        }),
        sceneCtrl.tweenCameraTo([0, 100, 800], tweenOutDuration),
      ]);
    },
  };

  // Object.assign(window, {
  //   downloadHomeTextures: async () => {
  //     traverseMaterialsAndDownloadMaps(gltfs.home.scene as any);
  //   },
  // });

  ////
  //// Stop GSAP ticker when the app is not visible
  ////
  {
    appDiv.addEventListener('visibilitychange', () => {
      if (appDiv.hidden) {
        sceneCtrl.setRenderingEnabled(false);
      } else {
        sceneCtrl.setRenderingEnabled(true);
      }
    });
  }

  return context;
}

export type SceneContext = Awaited<ReturnType<typeof createSceneContext>>;
