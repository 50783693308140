import { Group, Mesh, MeshStandardMaterial, SkinnedMesh } from 'three';

import { SkinColors } from '@/html/gui/SkinColors';
import { ModelNodeNames } from './constants/ModelNodeNames';

export function createPresenceSkinSetter(skinsHolderModel: Group) {
  if (!skinsHolderModel) {
    return { applySkinTo: () => void 0 };
  }

  const materials = {} as Record<string, MeshStandardMaterial>;

  skinsHolderModel.traverse(child => {
    if (child instanceof Mesh && child.material instanceof MeshStandardMaterial) {
      const material = child.material;
      const materialKey = material.name;
      materials[materialKey] = material;
    }
  });

  function skinSlugToMaterialNumber(skin: SkinColors) {
    switch (skin) {
      case SkinColors.Default:
        return 0;
      case SkinColors.Green:
        return 1;
      case SkinColors.Pink:
        return 2;
      case SkinColors.DeepBlue:
        return 3;
    }
  }

  function getMaterialKeyFor(bodyPart: 'body' | 'eye', skin: SkinColors) {
    const materialNum = skinSlugToMaterialNumber(skin);

    if (bodyPart === 'body') {
      return `Dolphin Body ${materialNum}`;
    }

    if (bodyPart === 'eye') {
      return `Dolphin Eye ${materialNum}`;
    }

    throw new Error(`Unknown body part: ${bodyPart}`);
  }

  return {
    applySkinTo(presenceModel: Group, skin: SkinColors) {
      try {
        const modelBody = findChildObjectByName<SkinnedMesh>(presenceModel, ModelNodeNames.Dolphin1.body);
        const modelEyeL = findChildObjectByName<SkinnedMesh>(presenceModel, ModelNodeNames.Dolphin1.eyeL);
        const modelEyeR = findChildObjectByName<SkinnedMesh>(presenceModel, ModelNodeNames.Dolphin1.eyeR);

        const bodyMaterial = materials[getMaterialKeyFor('body', skin)];
        const eyeMaterial = materials[getMaterialKeyFor('eye', skin)];

        modelBody.material = bodyMaterial;
        modelEyeL.material = eyeMaterial;
        modelEyeR.material = eyeMaterial;
      } catch (error) {
        console.error('🎨 Failed to set skin:', error);
      }
    },
  };
}

function findChildObjectByName<T extends Group | Mesh>(object: Group, name: string) {
  let result = null as T | null;
  object.traverse(child => {
    if (child.name === name) {
      result = child as T;
    }
  });

  if (!result) {
    throw new Error(`Child object not found: ${name}`);
  }

  return result;
}
