import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { LitElement, css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { AgeConfirmationCheckbox } from '../common/AgeConfirmationCheckbox';
import { ReusableStyles } from '../common/styles';
import { displayErrorMessage } from '../system/displayErrorMessage';
import { ModernInput } from './ModernInput';

import '../common/BlurryWrapper';
import '../common/SmallSpinner';
import './ModernInput';
import './TOSFooter';

const TAG_NAME = 'sign-up-form';

const defaultSignUpFormData = {
  email: '',
  password: '',
  passwordConfirmation: '',
};
type SignUpFormData = typeof defaultSignUpFormData;

////TODO: Move the platform buttons group to separte file to reuse in signup and signin
type Platform = 'google' | 'apple';

@customElement(TAG_NAME)
export class SignUpForm extends LitElement {
  static readonly tagName = TAG_NAME;

  static readonly styles = css`
    :host {
      position: fixed;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      zoom: 0.8;

      overflow-y: auto;
      padding: 10vmin 0;
    }

    .learn-more-btn-floating {
      position: absolute;
      top: 0px;
      right: 50%;
      transform: translateX(50%);
      cursor: pointer;
      padding: 32px;

      color: #999;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 116.667% */
      letter-spacing: 0.24px;
      text-decoration-line: underline;
    }

    .learn-more-btn {
      cursor: pointer;
      color: white;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: bold;
      line-height: 28px; /* 116.667% */
      letter-spacing: 0.24px;
      text-decoration-line: underline;
      margin: 1em;
    }

    .form-container {
      max-width: 390px;
      margin: auto;
      padding: 20px;
      border-radius: 10px;
      text-align: center;
      color: #ffffff;

      display: flex;
      flex-direction: column;
      align-items: center;

      z-index: 1;

      // overflow-y: auto;
      // height: 100%;
    }

    form {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      width: 90%;
    }

    .form-title {
      font-size: 24px;
      margin-bottom: 20px;

      color: #fff;
      text-align: center;
      font-family: Lora;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px; /* 106.667% */
      letter-spacing: 0.6px;
    }

    .form-subtitle {
      color: #34d399;
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 100% */
      letter-spacing: -0.24px;
    }

    .platform-login-row {
      display: flex;
      justify-content: center;
      align-items: stretch;
      margin-top: 1rem;
      gap: 1rem;
    }

    .platform-login-row > * {
      flex-grow: 1;
    }

    .pass-warning {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    }

    :host button.sign-up-button {
      margin-top: 1.5rem;
      width: 100%;
    }

    .footer {
      margin-top: 2rem;
    }

    ${ReusableStyles.glassButton}

    button.glass-button {
      max-width: 80%;
    }

    .sign-up-link {
      margin-top: 2em;
      color: #fff;

      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: -0.2px;
      text-decoration: underline;
    }

    .signing-sign {
      margin-top: 2em;
      color: #fff;

      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: -0.2px;
      opacity: 0.3;
    }
  `;

  readonly data = { ...defaultSignUpFormData };

  @query('.sign-up-button')
  private readonly signInButton!: HTMLButtonElement;

  @query('age-confirmation-checkbox')
  private readonly ageCheckbox!: AgeConfirmationCheckbox;

  public readonly onSignUpWithPlatformClick = createDelegateFunction<[platform: Platform]>();
  public readonly onSubmit = createDelegateFunction<[data: SignUpFormData]>();
  public readonly onSignInClick = createDelegateFunction();
  public readonly onLearnMore = createDelegateFunction();

  @property({ type: Boolean }) showAgeConfirmationCheckbox = false;

  @property({ type: Boolean }) loading = false;

  handleInputChange(event: CustomEvent) {
    if (!event.target) {
      console.error('No target found for input change event');
      return;
    }

    const inputElement = event.target as ModernInput;
    const inputName = inputElement.name;

    if (!inputName) {
      console.error('No input name found for input change event');
      return;
    }

    this.data[inputName as keyof SignUpFormData] = inputElement.value;
  }

  renderAgeConfirmationCheckboxMaybe() {
    if (!this.showAgeConfirmationCheckbox) return null;

    return html` <age-confirmation-checkbox></age-confirmation-checkbox> `;
  }

  render() {
    const onClickLearnMore = () => {
      this.onLearnMore.emit();
    };

    return html`
      <blurry-wrapper> </blurry-wrapper>

      <div class="form-container">
        <div class="form-title">Sign Up</div>
        <div class="form-subtitle">to prepare your Starter Program</div>

        <div id="learn-more-button" class="learn-more-btn" @click="${onClickLearnMore}">
          Learn about mindful shifting
        </div>

        <div class="platform-login-row">
          <platform-login-button
            id="google-login-button"
            platform="Google"
            icon="/images/icons/platform-google.svg"
            @click=${() => this.advanceMaybe(() => this.onSignUpWithPlatformClick.emit('google'))}
          ></platform-login-button>
          <platform-login-button
            id="apple-login-button"
            platform="Apple"
            icon="/images/icons/platform-apple.svg"
            @click=${() => this.advanceMaybe(() => this.onSignUpWithPlatformClick.emit('apple'))}
            disabled
          ></platform-login-button>
        </div>

        <form @submit=${this.handleSubmit} ?disabled=${this.loading}>
          <modern-input
            label="Email"
            name="email"
            type="email"
            placeholder="my@email.com"
            @input-change=${this.handleInputChange}
          ></modern-input>

          <modern-input
            label="Password"
            name="password"
            type="password"
            placeholder="mypassword"
            @input-change=${this.handleInputChange}
          ></modern-input>

          <modern-input
            label="Confirm Password"
            name="passwordConfirmation"
            type="password"
            placeholder="mypassword"
            @input-change=${this.handleInputChange}
          ></modern-input>

          ${this.renderAgeConfirmationCheckboxMaybe()}

          <button id="sign-up-button" type="submit" class="sign-up-button glass-button" ?disabled=${this.loading}>
            ${this.loading ? html`<small-spinner size="1em"></small-spinner>` : html`Sign Up`}
          </button>

          ${this.loading
            ? html` <a class="signing-sign"> Signing in... </a> `
            : html`
                <a id="sign-in-link" href="##" class="link sign-up-link" @click=${this.onSignInClick}> Or Sign In </a>
              `}
        </form>

        <tos-footer class="footer"> </tos-footer>
      </div>
    `;
  }

  setBusyState(busy: boolean) {
    this.loading = busy;

    if (this.signInButton) {
      this.signInButton.disabled = busy;
    }
  }

  advanceMaybe(advanceCallback: () => void) {
    if (this.showAgeConfirmationCheckbox) {
      const over13 = this.ageCheckbox?.isChecked();
      if (!over13) {
        displayErrorMessage('', 'Please confirm that you are over 13 years old.');
        return;
      }
    }

    this.setBusyState(true);

    advanceCallback();
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    console.log('Form submitted', this.data);
    this.advanceMaybe(() => this.onSubmit.emit(this.data));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: SignUpForm;
  }
}
