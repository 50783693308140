import { appContext } from '@/context/appContext';
import { ShifterInputPickOption } from './ShifterInputPickOption';
import { createElementFadeInOutControl } from '../utils/createElementFadeInOutControl';

export async function displayOptionsPicker<T>(parentElement: Element, buttonDescriptors: ButtonDescriptor<T>[]) {
  const unblockFF = appContext.fastforwarder.reasonsToPreventFastForwarding.add('display-option-picker');

  const optionPicker = new ShifterInputPickOption();
  optionPicker.buttonDescriptors = buttonDescriptors;

  parentElement.append(optionPicker);
  await optionPicker.updateComplete;

  const fader = createElementFadeInOutControl(optionPicker);
  await fader.fadeIn(0.6);

  const pickedOption = await optionPicker.onOptionPicked.waitUntil();

  await fader.fadeOut(0.6);
  optionPicker.remove();

  unblockFF();

  return pickedOption as T;
}

interface ButtonDescriptor<T> {
  label: string;
  value: T;
}
